export const RightAngleIcon = ({
  width = '12',
  height = '12',
  color = '#989898',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path d="M1 0V11.5H12" stroke={color} />
  </svg>
);
