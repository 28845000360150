export const CircleAlertIcon = ({
  width = '22',
  height = '22',
  stroke = 'white',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M11.0666 19.5156C15.9409 19.5156 19.8923 15.5642 19.8923 10.6899C19.8923 5.81564 15.9409 1.86426 11.0666 1.86426C6.19235 1.86426 2.24097 5.81564 2.24097 10.6899C2.24097 15.5642 6.19235 19.5156 11.0666 19.5156Z"
      stroke={stroke}
      strokeWidth="1.55747"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0667 6.24219L11.0667 11.3939"
      stroke={stroke}
      strokeWidth="1.55747"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0667 14.2202H11.0747"
      stroke={stroke}
      strokeWidth="1.55747"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
