import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalTitle, ModalWindow } from '../../../components/Modal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CloudUploadIcon } from '../../../components/icons/CloudUploadIcon';
import classNames from '../../../helper/classNames';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import {
  publishToCmsApi,
  updateEpisodeSettingsApi,
  verifyMeetingSeriesSlugApi,
} from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';
import { PROCESSOR_BASE_URL } from '../../../helper/constants';

export const PublishToCmsModal = ({
  handleClose,
  highlights = [],
  workspace,
  series,
  refreshSeries,
  localDispatch,
}) => {
  const { workspaceId } = workspace || {};
  const { slug, meetingSeriesId } = series || {};
  const firstHighlight = highlights[0];
  const {
    title,
    highlightId,
    HIGHLIGHT_SOCIAL_MEDIA_POSTS,
    sentences = [],
  } = firstHighlight || {};
  const { socialMediaPosts } = HIGHLIGHT_SOCIAL_MEDIA_POSTS || {};
  const { youtube } = socialMediaPosts || {};
  const { text: youtubeText } = youtube || {};

  const [settings, setSettings] = useState(series?.settings);
  const { highlights: highlightsSettings = {} } = settings || {};
  const highlightSettings = highlightsSettings?.[highlightId];
  const { socialMediaPosts: highlightSettingsSocialMediaPosts } =
    highlightSettings || {};
  const { cms: highlightCmsSettings } = highlightSettingsSocialMediaPosts || {};
  const { text: settingsText, transcript: settingsTranscript } =
    highlightCmsSettings || {};

  const [summary, setSummary] = useState(settingsText || youtubeText);
  const [transcript, setTranscript] = useState(
    settingsTranscript || sentences.map((s) => s.text).join(' ')
  );
  const [slugInput, setSlugInput] = useState(slug || '');
  const [slugError, setSlugError] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const [publishData, setPublishData] = useState(null);
  const intervalRef = useRef(null);

  const isPublishButtonDisabled =
    summary.trim().length === 0 ||
    transcript.trim().length === 0 ||
    slugInput.trim().length === 0 ||
    isLoading;

  const fetchPublishToCms = useCallback(async () => {
    const result = await fetch(
      `${PROCESSOR_BASE_URL}/asset?meetingSeriesId=${meetingSeriesId}&groupId=${highlightId}&workspaceId=${workspaceId}&processorId=SOLO_PUBLISH_TO_CMS`
    );

    return result.json();
  }, [highlightId, meetingSeriesId, workspaceId]);

  const refreshPublishToCms = useCallback(async () => {
    const { result } = await fetchPublishToCms();
    const { status, data } = result || {};
    const { message = '', type } = status || {};

    if (type === 'COMPLETE') {
      if (message.length > 0) {
        setPublishError('There was a problem, please try again later.');
        setPublishData(data);
        data?.id ? setIsPublished(true) : setIsPublished(false);
      } else {
        setPublishData(data);
        data?.id ? setIsPublished(true) : setIsPublished(false);
      }
      clearInterval(intervalRef.current);
      setIsLoading(false);
      return;
    }
  }, [fetchPublishToCms]);

  const verifySlug = async (slug) => {
    try {
      setSlugError(null);
      const isSlugValid = /^[a-zA-Z0-9_-]+$/.test(slug);
      if (!isSlugValid) {
        setSlugError('Slug can only contain alphanumeric characters, -, and _');
        return false;
      }
      const { error } = await verifyMeetingSeriesSlugApi({
        meetingSeriesId,
        slug,
      });

      if (error) {
        setSlugError(error);
        return false;
      }

      return true;
    } catch (error) {
      logerror({
        ...fromError(error),
        message: `Error in verifySlug in PublishToCmsModal.jsx`,
      });
      setSlugError('Internal Server Error');
      return false;
    }
  };

  const updateHighlightsSettings = async (summary, transcript) => {
    try {
      const newHighlights = {
        ...highlightsSettings,
        [highlightId]: {
          ...highlightsSettings[highlightId],
          socialMediaPosts: {
            ...highlightsSettings[highlightId]?.socialMediaPosts,
            cms: {
              text: summary,
              transcript,
            },
          },
        },
      };
      const result = await updateEpisodeSettingsApi(meetingSeriesId, {
        highlights: newHighlights,
      });
      setSettings(result.settings);
      return true;
    } catch (error) {
      logerror({
        ...fromError(error),
        message: `Error in updateHighlightsSettings in PublishToCmsModal.jsx`,
      });
      setPublishError('There was a problem, please try again');
      return false;
    }
  };

  const handlePublish = async ({ mode }) => {
    setSlugError(null);
    setPublishError(null);
    setIsLoading(true);

    const trimmedSlugInput = slugInput.trim();
    const isSlugValid = await verifySlug(trimmedSlugInput);
    if (!isSlugValid) {
      setIsLoading(false);
      return;
    }

    refreshSeries(undefined, (series) =>
      localDispatch({
        type: 'REFRESH_SERIES',
        series,
      })
    );

    const trimmedSummary = summary.trim();
    const trimmedTranscript = transcript.trim();
    const updatedHighlightSettings = await updateHighlightsSettings(
      trimmedSummary,
      trimmedTranscript
    );
    if (!updatedHighlightSettings) {
      setIsLoading(false);
      return;
    }

    const isPublishing = await publishToCmsApi({
      summary: trimmedSummary,
      transcript: trimmedTranscript,
      taskDetails: { meetingSeriesId, workspaceId, groupId: highlightId },
      sessionSlug: trimmedSlugInput,
      mode,
    });

    if (isPublishing) {
      intervalRef.current = setInterval(() => {
        refreshPublishToCms();
      }, [5_000]);
    } else {
      setPublishError('There was a problem, please try again');
      setIsLoading(false);
    }
  };

  const handleUnpublish = async () => {
    setSlugError(null);
    setPublishError(null);
    setIsLoading(true);

    const isUnpublishing = await publishToCmsApi({
      taskDetails: { meetingSeriesId, workspaceId, groupId: highlightId },
      mode: 'unpublish',
    });

    if (isUnpublishing) {
      intervalRef.current = setInterval(() => {
        refreshPublishToCms();
      }, [5_000]);
    } else {
      setPublishError('There was a problem, please try again');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadModal = async () => {
      setIsModalLoading(true);
      setIsLoading(true);
      const { result } = await fetchPublishToCms();

      if (!result) {
        setIsLoading(false);
        setIsModalLoading(false);
        return;
      }
      const { status, data } = result || {};
      const { message = '', type } = status || {};

      if (type === 'COMPLETE') {
        if (message.length > 0) {
          setPublishError('There was a problem, please try again');
          setPublishData(data);
          data?.id ? setIsPublished(true) : setIsPublished(false);
        } else {
          setPublishData(data);
          data?.id ? setIsPublished(true) : setIsPublished(false);
        }
        clearInterval(intervalRef.current);
        setIsLoading(false);
        setIsModalLoading(false);
        return;
      }

      intervalRef.current = setInterval(() => {
        refreshPublishToCms();
      }, [5_000]);
      setIsModalLoading(false);
    };
    loadModal();
  }, [fetchPublishToCms, refreshPublishToCms]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  if (isModalLoading) return null;

  if (highlights.length === 0) {
    return (
      <ModalWindow size={Modal.size.xl} onCancel={handleClose}>
        <ModalTitle>No content kit created</ModalTitle>
      </ModalWindow>
    );
  }

  return (
    <ModalWindow size={Modal.size.xl} overflow="overflow-visible">
      <div className="flex flex-col px-11 py-9 h-[70vh] overflow-auto purple-scrollbar">
        <button
          className="self-end text-2xl text-blue-gray absolute top-2 right-6"
          onClick={handleClose}
        >
          ×
        </button>
        <div className="flex justify-between w-full items-center">
          <span className="text-black text-xl font-semibold">{title}</span>
          <div className="flex flex-col items-end">
            <div className="flex gap-2">
              {isPublished && publishData?.id ? (
                <div className="flex gap-6 items-center">
                  <button
                    className={classNames(
                      'text-white text-[13px] flex gap-2 items-center px-3 py-2.5',
                      isPublishButtonDisabled ? 'bg-[#C8AAF4]' : 'bg-purple'
                    )}
                    disabled={isPublishButtonDisabled}
                    onClick={() => {
                      handleUnpublish();
                    }}
                  >
                    Unpublish
                  </button>
                  <button
                    className={classNames(
                      'text-white text-[13px] flex gap-2 items-center px-3 py-2.5',
                      isPublishButtonDisabled ? 'bg-[#C8AAF4]' : 'bg-purple'
                    )}
                    disabled={isPublishButtonDisabled}
                    onClick={() => {
                      handlePublish({ mode: 'republish' });
                    }}
                  >
                    <CloudUploadIcon /> Republish
                  </button>
                </div>
              ) : (
                <button
                  className={classNames(
                    'text-white text-[13px] flex gap-2 items-center px-3 py-2.5',
                    isPublishButtonDisabled ? 'bg-[#C8AAF4]' : 'bg-purple'
                  )}
                  disabled={isPublishButtonDisabled}
                  onClick={() => handlePublish({ mode: 'publish' })}
                >
                  <CloudUploadIcon /> Publish
                </button>
              )}
              {isLoading && <LoadingSpinner width="20px" />}
            </div>
            {publishError && (
              <span className="mt-1.5 text-[#EA0808] text-xxs font-semibold">
                {publishError}
              </span>
            )}
          </div>
        </div>
        <span className="mt-4 text-black text-sm font-semibold">
          Video Page
        </span>
        <div className="px-3 py-2 border border-[#EDEDED] bg-[#F7F7F7] rounded-[5px]">
          {isPublished && publishData?.id ? (
            <a
              className="text-[#2D1FF9] decoration-[#2D1FF9] underline text-xs font-courier-prime font-bold"
              href={`https://www.zync.ai/video/${publishData?.fieldData?.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.zync.ai/video/{publishData?.fieldData?.slug}
            </a>
          ) : (
            <Link
              className="text-[#2D1FF9] decoration-[#2D1FF9] underline text-xs font-courier-prime font-bold"
              to={`/${workspaceId}/videofeed/${highlightId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Video Page
            </Link>
          )}
        </div>
        <div className="flex justify-between w-full">
          <span className="mt-2 text-black text-sm font-semibold">Summary</span>
        </div>
        <textarea
          className="resize-none w-full px-3 py-2 rounded-[5px] border border-[#C0C0C0] outline-none h-40 min-h-[160px] text-black"
          value={summary}
          onChange={(event) => {
            setSummary(event.target.value);
          }}
        ></textarea>
        <div className="flex justify-between w-full">
          <span className="mt-2 text-black text-sm font-semibold">
            Transcript
          </span>
        </div>
        <textarea
          className="resize-none w-full px-3 py-2 rounded-[5px] border border-[#C0C0C0] outline-none h-40 min-h-[160px] text-black"
          value={transcript}
          onChange={(event) => {
            setTranscript(event.target.value);
          }}
        ></textarea>
        <span className="mt-5 text-black text-sm font-semibold">Page Link</span>
        <div className="flex gap-0.5 items-center">
          <span className="text-[#282828] text-xxs">
            https://www.zync.ai/video/
          </span>
          <input
            value={slugInput}
            onChange={(e) => setSlugInput(e.target.value)}
            className="border border-[#C0C0C0] rounded-[5px] p-1 text-[#282828] text-xs outline-none"
          />
        </div>
        {slugError && (
          <span className="mt-1.5 text-[#EA0808] text-[8px] font-semibold">
            {slugError}
          </span>
        )}
      </div>
    </ModalWindow>
  );
};
