import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ColorSelectInput } from './common/ColorSelectInput';
import { uploadBrandKitLogo } from '../helper/api';
import { ImageUploader } from './ImageUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AttachmentIcon } from './icons/AttachmentIcon';
import { fromError, logerror } from '../helper/contextualLogger';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { Button } from './common/Button';
import { isHexColor } from '../helper/isHexColor';
import { PreviewSlide } from './PreviewSlide';
import { getContrastColor } from 'zync-common/helper/color';
import { useSelector } from 'react-redux';
import { useTrackActivity } from '../hooks/track';
import { DEFAULT_LOGO_URL } from 'zync-common/types';

const placeholderLogoUrl = getStaticAssetUrl('default_logo.png');
export const blankLogoUrl = getStaticAssetUrl('default_blank_logo.png');

export const fileNameWithoutWorkspaceIdAndDate = (fileName) =>
  fileName.split('/').at(-1).split('-').at(-1);

const LogoUploader = ({
  logoUrl,
  handleChange,
  workspaceId,
  removeLogo = false,
}) => {
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmitLogo = useCallback(
    async (buffer, type, name) => {
      setIsSubmittingFile(true);

      try {
        const uploadResult = await uploadBrandKitLogo(
          workspaceId + '/' + name,
          buffer,
          type
        );
        if (uploadResult) {
          const newUrl =
            'https://cdn.solorecording.studio/' +
            uploadResult?.result?.metadata?.name;

          handleChange('logoUrl')(newUrl);
        } else {
          setError('Error submitting logo, please try again.');
        }
      } catch (error) {
        logerror(fromError(error));
      }
      setIsSubmittingFile(false);
    },
    [handleChange, workspaceId]
  );

  const handleRemoveLogo = useCallback(() => {
    handleChange('logoUrl')(placeholderLogoUrl);
  }, [handleChange]);

  const handleRemoveLogoCompletely = useCallback(() => {
    handleChange('logoUrl')(blankLogoUrl);
  }, [handleChange]);

  const onFileSizeExceeded = useCallback(() => {
    setFileSizeExceeded(true);
  }, []);

  return (
    <div>
      {logoUrl && (
        <div className="flex gap-4 justify-between">
          <div className="flex items-center gap-2">
            <div className="flex gap-1 mb-4 items-center">
              <div className="shrink-0">
                <AttachmentIcon />
              </div>
              <div className="text-[#717480]">
                {fileNameWithoutWorkspaceIdAndDate(logoUrl)}
              </div>
              {logoUrl !== placeholderLogoUrl && logoUrl !== blankLogoUrl && (
                <button
                  onClick={handleRemoveLogo}
                  className="border border-red rounded-full w-5 h-5 flex items-center justify-center scale-80"
                >
                  <span className="sr-only">
                    Click to remove the uploaded logo
                  </span>
                  <div className="shrink-0 w-5 h-5  flex items-center justify-center">
                    <FontAwesomeIcon icon="times" size="xs" color="#fa2a66" />
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {error && <div className="text-red text-xs font-light mb-2">{error}</div>}
      <div className="w-full" onClick={() => setFileSizeExceeded(false)}>
        <ImageUploader
          disabled={isSubmittingFile}
          loading={isSubmittingFile}
          label={
            <div className="flex gap-2 items-center justify-center">
              <AttachmentIcon className="stroke-purple" />
              Upload Logo
            </div>
          }
          fileSizeBytes={2e6}
          onFileSizeExceeded={onFileSizeExceeded}
          buttonColor={Button.colors.PURPLE_LITE}
          onImageSubmit={handleSubmitLogo}
          appendTimeStampToFile={true}
          accept="image/png, image/gif, image/jpeg, image/jpg"
        />
        {fileSizeExceeded && (
          <p className="text-red text-xxs">
            Logo size exceeded. Try to upload a file that is less than 2
            megabytes (2MB).
          </p>
        )}
        {removeLogo ? (
          <div className="mt-4">
            <Button
              color={Button.colors.WHITE}
              onClick={handleRemoveLogoCompletely}
            >
              <span className="text-xs">Remove Logo</span>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const getInitialState = ({ brandKit }) => ({
  font: brandKit.font,
  primaryColor: brandKit.primaryColor,
  primaryContrast: brandKit.primaryContrast,
  accentColor: brandKit.accentColor,
  accentContrast: brandKit.accentContrast,
  backgroundColor: brandKit.backgroundColor,
  logoUrl: brandKit.logoUrl,
  ctaText: brandKit.ctaText,
  backdrop_image: brandKit.backdrop_image,
});

const checkIsColor = (strColor) => {
  return /^#(([0-9A-Fa-f]{2}){3,4}|[0-9A-Fa-f]{3,4})$/.test(strColor);
};

export const WorkspaceBrandKitForm = ({ workspace, setWorkspace }) => {
  const isInitialized = useRef(false);
  const userName = useSelector((state) => state.auth.user.userName);
  const userBio = useSelector((state) => state.auth.user.bio);
  const { soloSettings } = workspace || {};
  const { defaultSpeaker } = soloSettings || {};

  const hostBio =
    workspace?.workspacePlan === 'solo' ? defaultSpeaker?.jobTitle : userBio;
  const hostName =
    workspace?.workspacePlan === 'solo' ? defaultSpeaker?.fullName : userName;

  const [brandKitSettings, setBrandKitSettings] = useState(
    getInitialState(workspace)
  );
  const [colorError, setColorError] = useState(null);

  const {
    primaryColor,
    primaryContrast,
    accentColor,
    accentContrast,
    backgroundColor,
    font,
    logoUrl,
    ctaText,
  } = brandKitSettings;

  const track = useTrackActivity();

  const handleChange = (key) => (value) => {
    setColorError(null);
    if (
      (key === 'accentColor' || key === 'primaryColor') &&
      !checkIsColor(value)
    ) {
      setColorError(
        'Invalid hex color pasted in either accent or primary color'
      );
      return;
    }
    setBrandKitSettings((prevSettings) => {
      const newSettings = {
        ...prevSettings,
        [key]: value,
        primaryContrast:
          key === 'primaryColor' && isHexColor(value)
            ? getContrastColor(value)
            : prevSettings.primaryContrast,
        accentContrast:
          key === 'accentColor' && isHexColor(value)
            ? getContrastColor(value)
            : prevSettings.accentContrast,
      };

      if (key === 'accentColor' || key === 'primaryColor') {
        track('Settings: Brandkit Colors Changed');
      }

      if (key === 'logoUrl') {
        track('Settings: Brandkit Logo Changed');
      }

      if (!isInitialized.current) {
        return;
      }

      setWorkspace({ ...workspace, brandKit: newSettings });

      return newSettings;
    });
  };

  useEffect(() => {
    isInitialized.current = true;
  }, []);

  const handleRestoreValue = (key, valueToRestore) => (event) => {
    const value = event.currentTarget.value;

    if (!checkIsColor(value)) {
      setBrandKitSettings((prevSettings) => ({
        ...prevSettings,
        [key]: valueToRestore,
      }));
    }
  };

  return (
    <div className="bg-white mb-8">
      <h3 className="text-blue-dark font-semibold bg-purple/5 px-4 py-3">
        Brand Kit
      </h3>
      <div className="px-4 pt-5 flex justify-between gap-4">
        <div className="flex flex-col gap-3">
          <div>
            <ColorSelectInput
              label="Primary color"
              value={primaryColor}
              onChange={handleChange('primaryColor')}
              onBlur={handleRestoreValue(
                'primaryColor',
                workspace.brandKit.primaryColor
              )}
            />
          </div>
          <div>
            <ColorSelectInput
              label="Accent color"
              value={accentColor}
              onChange={handleChange('accentColor')}
              onBlur={handleRestoreValue(
                'accentColor',
                workspace.brandKit.accentColor
              )}
            />
          </div>
          {colorError && (
            <div className="text-red text-xs font-medium">{colorError}</div>
          )}
          {/*<ColorSelectInput
            label="Background color"
            value={backgroundColor}
            onChange={handleChange('backgroundColor')}
            onBlur={handleRestoreValue(
              'backgroundColor',
              workspace.brandKit.backgroundColor
            )}
          />*/}

          {/* <label className="text-blue-dark text-sm font-medium">Font</label>
          <style>
            {`
              .select { background-color: white; }
              .select-items-container { width: 100%; }
           `}
          </style>*/}
          <LogoUploader
            logoUrl={logoUrl}
            handleChange={handleChange}
            workspaceId={workspace.workspaceId}
            removeLogo={logoUrl !== DEFAULT_LOGO_URL}
          />
        </div>

        <div className="flex-shrink-0">
          <h2 className="text-xl font-semibold text-blue-dark mb-2">Preview</h2>
          <h3 className="text-[#696F8C] mb-4">
            This is how your assets will look when we apply your brand kit
            settings.
          </h3>
          <PreviewSlide
            brandKit={{
              primaryColor,
              primaryContrast,
              accentColor,
              accentContrast,
              backgroundColor,
              font,
              logoUrl,
              ctaText,
              backdrop_image: workspace.brandKit.backdrop_image,
            }}
            sceneTemplateId={
              workspace.workspacePlan === 'solo'
                ? 'scene_solo_studio_brand_kit'
                : 'scene_interview_question_preview'
            }
            width={500}
            height={(500 / 16) * 9}
            hostName={hostName}
            hostBio={hostBio}
          />
        </div>
      </div>
    </div>
  );
};
