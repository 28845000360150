import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

import classNames from '../../../helper/classNames';
import { customStylesPrefixes, setInlineStyleMap } from '../helpers';

/* This component renders a color picker that is used with draft js library */
const ColorPicker = React.memo(({ onSelect, selected, initialSelected }) => {
  const handleChangeColor = (event) => {
    event.preventDefault();
    const color = event.target.value;
    const colorStyle = `${customStylesPrefixes.COLOR}${color}`;

    /* We need to create a new entry in inline style map for color to appear */
    setInlineStyleMap((inlineStyleMap) => {
      inlineStyleMap[colorStyle] = {
        color,
      };
    });

    onSelect(colorStyle, customStylesPrefixes.COLOR);
  };

  const onClick = (event) => {
    event.preventDefault();
  };

  const color = selected?.label || initialSelected?.label;

  return (
    <div className="relative">
      <div className="absolute inset-0 m-auto flex items-center justify-center gap-2">
        <div
          className="h-6 w-6 rounded-md "
          style={{
            backgroundColor: color,
          }}
        />
        <div className={classNames('transition-transform', 'rotate-0')}>
          <FaChevronDown size={14} color="#696F8C" />
        </div>
      </div>
      <input
        type="color"
        defaultValue={color}
        onMouseDown={onClick}
        onInput={handleChangeColor}
        className="opacity-0"
      />
    </div>
  );
});

export default React.memo(ColorPicker);
