import classNames from '../../helper/classNames';

const BORDER_WIDTH_VARIANTS = {
  NONE: 'NONE',
  THIN: 'THIN',
};

const BORDER_WIDTH_VARIANTS_CLASSNAMES = {
  NONE: 'border-0',
  THIN: 'border',
};

const BORDER_COLOR_VARIANTS = {
  TRANSPARENT: 'TRANSPARENT',
  LIGHT_GRAY: 'LIGHT_GRAY',
};

const BORDER_COLOR_VARIANTS_CLASSNAMES = {
  TRANSPARENT: 'border-transparent',
  LIGHT_GRAY: 'border-[#EBEAED]',
};

const BORDER_RADIUS_VARIANTS = {
  NONE: 'NONE',
  ROUNDED: 'ROUNDED',
};

const BORDER_RADIUS_VARIANTS_CLASSNAMES = {
  NONE: 'rounded-none',
  ROUNDED: 'rounded',
};

export const ZyncUpUrl = ({
  zyncUpUrl,
  setZyncUpUrl,
  borderWidth = BORDER_WIDTH_VARIANTS.NONE,
  borderColor = BORDER_COLOR_VARIANTS.TRANSPARENT,
  borderRadius = BORDER_RADIUS_VARIANTS.NONE,
}) => {
  return (
    <div className="flex flex-grow flex-col gap-2 self-start min-w-0">
      <input
        type="text"
        defaultValue={zyncUpUrl}
        onChange={(event) => setZyncUpUrl(event.currentTarget.value)}
        className={classNames(
          'px-9 py-6 font-medium placeholder:text-[#97A0AF] text-blue-gray',
          BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
          BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
          BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
        )}
        maxLength={90}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        placeholder="ZyncUp Session Url"
      />
    </div>
  );
};

ZyncUpUrl.borderWidth = BORDER_WIDTH_VARIANTS;
ZyncUpUrl.borderColor = BORDER_COLOR_VARIANTS;
ZyncUpUrl.borderRadius = BORDER_RADIUS_VARIANTS;
