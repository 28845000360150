import React from 'react';
import { Button } from './common/Button';
import mixpanel from 'mixpanel-browser';

export const createCustomerPortalLink = (userId) => {
  return `/stripe/create-customer-portal-session?userId=${encodeURIComponent(
    userId
  )}`;
};

// TODO: Remove horrifically insecure query param of userId.
export const StripeCustomerPortalButton = ({ userId }) => {
  return (
    <form
      className="flex"
      method="POST"
      action={createCustomerPortalLink(userId)}
    >
      <Button
        type="submit"
        color={Button.colors.PURPLE}
        padding={Button.padding.NONE}
        onClick={() => {
          mixpanel.track(`Settings: Manage Payment Clicked`, {
            distinct_id: userId,
          });
        }}
      >
        <span className="text-base font-medium px-4 py-2 block whitespace-nowrap">
          Manage Payment
        </span>
      </Button>
    </form>
  );
};
