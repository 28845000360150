import React, { useState } from 'react';
import {
  uploadEventPresenterImage,
  updateSeriesApi,
  sendSpeakerInvite,
} from '../../../helper/api';
import { fromError, logerror } from '../../../helper/contextualLogger';
import { ImageUploader } from '../../ImageUploader';
import { AttachmentIcon } from '../../icons/AttachmentIcon';
import { Button } from '../../common/Button';
import { RemoveUserIcon } from '../../../pages/Series/Attendees.styled';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';

export const presenterTypes = {
  SPEAKER: 'speaker',
  HOST: 'host',
};

const capitalizeFirstLetterOfPresenterType = (presenterType) =>
  presenterType.charAt(0).toUpperCase() + presenterType.slice(1);

const PresenterTextInput = ({
  value,
  setValue,
  placeholder,
  textLength,
  type = 'text',
}) => (
  <input
    className="py-2.5 px-4 bg-purple/5 border-[#EBEAED] border border-solid rounded text-sm placeholder:text-[#97A0AF] text-blue-gray outline-purple focus:border-purple hover:border-purple w-full"
    value={value}
    onChange={(event, value) => setValue(event.currentTarget.value)}
    placeholder={placeholder}
    maxLength={textLength ? textLength : ''}
    type={type}
  />
);

const PresenterForm = ({ presenterType, series, localDispatch }) => {
  const { meetingSeriesId, settings } = series;
  const { eventPresenters = [] } = settings || {};
  const [jobTitle, setJobTitle] = useState('');
  const [fullName, setFullName] = useState('');
  const [linkedInUrl, setLinkedInUrl] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [presenterPictureUrl, setPresenterPictureUrl] = useState('');
  const [isSubmittingFile, setIsSubmittingFile] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [error, setError] = useState(false);
  const formattedPresenterType =
    capitalizeFirstLetterOfPresenterType(presenterType);

  const handleUploadEventPresenterImage = async (buffer, type, name) => {
    setIsSubmittingFile(true);

    try {
      const uploadResult = await uploadEventPresenterImage(
        meetingSeriesId + '/' + name,
        buffer,
        type
      );

      if (uploadResult) {
        const newUrl =
          'https://cdn.solorecording.studio/' +
          uploadResult?.result?.metadata?.name;

        setPresenterPictureUrl(newUrl);
      }
    } catch (error) {
      logerror(fromError(error));
    }
    setIsSubmittingFile(false);
  };

  const handleAddPresenter = async (e) => {
    e.preventDefault();
    setIsSubmittingForm(true);
    if (!jobTitle.trim()) {
      setIsSubmittingForm(false);
      setError('Job Title is required.');
      return;
    }
    if (!fullName.trim()) {
      setIsSubmittingForm(false);
      setError('Name is required.');
      return;
    }
    if (!linkedInUrl.trim()) {
      setIsSubmittingForm(false);
      setError('LinkedIn url is required.');
      return;
    }
    if (!emailAddress.trim()) {
      setIsSubmittingForm(false);
      setError('Email address is required.');
      return;
    }

    const result = await updateSeriesApi(meetingSeriesId, {
      settings: {
        eventPresenters: [
          ...eventPresenters,
          {
            fullName,
            linkedInUrl,
            jobTitle,
            presenterPictureUrl,
            type: presenterType,
            emailAddress,
          },
        ],
      },
    });
    localDispatch({ type: 'REFRESH_SERIES', series: result });
    setFullName('');
    setJobTitle('');
    setLinkedInUrl('');
    setPresenterPictureUrl('');
    setIsSubmittingForm(false);
  };

  return (
    <form className="flex flex-col gap-2" onSubmit={handleAddPresenter}>
      <div className="font-medium text-sm">{formattedPresenterType + 's'}</div>
      <div className="flex justify-between w-full gap-4">
        <div className="flex gap-4 h-[42px] w-full">
          <PresenterTextInput
            value={fullName}
            setValue={setFullName}
            placeholder="Name"
            textLength={30}
          />
          <PresenterTextInput
            value={jobTitle}
            setValue={setJobTitle}
            placeholder="Headline e.g. CEO Acme Inc"
            textLength={50}
          />
          <PresenterTextInput
            value={linkedInUrl}
            setValue={setLinkedInUrl}
            placeholder="LinkedIn URL"
          />
          <PresenterTextInput
            value={emailAddress}
            setValue={setEmailAddress}
            placeholder="Email Address"
            type="email"
          />
          {presenterPictureUrl ? (
            <img
              src={presenterPictureUrl}
              alt="presenter"
              className="object-contain w-9 h-9"
            />
          ) : (
            <div className="min-w-[160px]">
              <ImageUploader
                disabled={isSubmittingFile}
                loading={isSubmittingFile}
                label={
                  <div className="flex gap-2 items-center justify-center">
                    <AttachmentIcon className="stroke-[#97A0AF]" />
                    Upload Image
                  </div>
                }
                onImageSubmit={handleUploadEventPresenterImage}
                appendTimeStampToFile={false}
                buttonColor={Button.colors.PURPLE_OPACITY_5}
              />
            </div>
          )}
        </div>
        <div className="h-[42px] flex w-[200px]">
          <Button
            color={Button.colors.PURPLE}
            size={Button.sizes.FULL}
            type="submit"
            state={
              isSubmittingForm ? Button.states.LOADING : Button.states.DEFAULT
            }
          >
            <div className="text-xs">Add {formattedPresenterType}</div>
          </Button>
        </div>
      </div>
      <div className="text-red text-sm">{error}</div>
    </form>
  );
};

export const EventPresenters = ({ series, localDispatch }) => {
  const { settings, meetingSeriesId } = series || {};
  const { eventPresenters = [] } = settings || {};

  const handleDeletePresenter = async (presenter) => {
    const result = await updateSeriesApi(meetingSeriesId, {
      settings: {
        eventPresenters: eventPresenters.filter(
          (eventPresenter) =>
            eventPresenter.linkedInUrl !== presenter.linkedInUrl
        ),
      },
    });
    localDispatch({ type: 'REFRESH_SERIES', series: result });
  };

  return (
    <div className="flex flex-col gap-8 w-full">
      {eventPresenters.length > 0 && (
        <div className="flex flex-col border-[#EBEAED] w-full border border-solid">
          <div className="py-2.5 bg-purple/5 text-sm text-blue-dark font-medium flex border-[#EBEAED] w-full border-b border-solid px-6 gap-4">
            <div className="w-[20%]">Name</div>
            <div className="w-[20%]">Job Title</div>
            <div className="w-[20%]">LinkedIn</div>
            <div className="w-[20%]">Image</div>
          </div>
          <div className="py-4 px-6 flex w-full text-blue-dark font-medium text-sm flex-col gap-4">
            {eventPresenters.map((presenter) => (
              <div
                className="flex w-full items-center gap-4"
                key={presenter.fullName}
              >
                <div className="w-[20%] space-x-3">
                  <RemoveUserIcon
                    style={{ color: '#F8155C' }}
                    icon={faMinusSquare}
                    onClick={() => handleDeletePresenter(presenter)}
                  />
                  {presenter.fullName}
                </div>
                <div className="w-[20%]">{presenter.jobTitle}</div>
                <div className="w-[20%]">
                  <a
                    href={presenter.linkedInUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-light text-sm break-words"
                  >
                    {presenter.linkedInUrl}
                  </a>
                </div>
                <div className="w-[20%]">
                  {!!presenter.presenterPictureUrl && (
                    <img
                      src={presenter.presenterPictureUrl}
                      alt="presenter"
                      className="object-contain h-9 w-9"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="py-4 px-6 flex w-full text-blue-dark font-medium text-sm flex-col gap-4">
            {eventPresenters
              .filter((ep) => {
                return ep.type === 'speaker';
              })
              .map((presenter) => (
                <div
                  className="flex w-full items-center justify-between"
                  key={presenter.emailAddress}
                >
                  Send Calendar Invite to {presenter.fullName} at{' '}
                  {presenter.emailAddress}
                  <div className="w-28">
                    <Button
                      color={Button.colors.PURPLE}
                      size={Button.sizes.FULL}
                      type="submit"
                      onClick={async () => {
                        const result = await sendSpeakerInvite(
                          meetingSeriesId,
                          presenter.emailAddress
                        );
                        localDispatch({
                          type: 'REFRESH_SERIES',
                          series: result,
                        });
                      }}
                      state={
                        presenter?.invited
                          ? Button.states.DISABLED
                          : Button.states.DEFAULT
                      }
                    >
                      <div className="text-xs">Send Invite</div>
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      <PresenterForm
        presenterType={presenterTypes.SPEAKER}
        series={series}
        localDispatch={localDispatch}
      />
      <PresenterForm
        presenterType={presenterTypes.HOST}
        series={series}
        localDispatch={localDispatch}
      />
    </div>
  );
};
