import { useCallback, useEffect, useState } from 'react';
import { checkpointNames, ZYNC_UP_URL } from './constants';
import { useUserWorkspaces } from '../../hooks/useUserWorkspaces';
import { validateTimes } from '../../helper/rsvp';
import { fetchWorkspaceApi } from '../../helper/api';
import { buttonFunctionalityClasses } from '../../components/onboarding/constants';
import axios from 'axios';
import { PROCESSOR_BASE_URL } from '../../helper/constants';

export const checkpoints = {
  [checkpointNames.ZYNC_UP_URL]: ZYNC_UP_URL,
};

export const useCreateNewR = ({
  createNewEvent,
  scheduledEvent,
  workspaceId,
}) => {
  const [zyncUpUrl, setZyncUpUrl] = useState('');
  const [zyncUpError, setZyncUpError] = useState(null);
  const [checkpointsData] = useState(checkpoints);
  const [workspace, setWorkspace] = useState(null);
  const { brandKit } = workspace || {};
  const [ZYNC_UP_URL] = [checkpointsData[checkpointNames.ZYNC_UP_URL]];

  const [checkpoint] = useState(ZYNC_UP_URL);

  const [nextButtonStyling, setNextButtonStyling] = useState(
    buttonFunctionalityClasses.ENABLE
  );

  const [previousButtonStyling, setPreviousButtonStyling] = useState(
    buttonFunctionalityClasses.DISABLE
  );

  const [isLoading, setIsLoading] = useState(false);

  const { currentWorkspaceId } = useUserWorkspaces();
  useEffect(() => {
    const getWorkspace = async () => {
      const { result } = await fetchWorkspaceApi(currentWorkspaceId);
      if (!result) return;
      setWorkspace(result);
    };
    getWorkspace();
  }, [currentWorkspaceId]);

  const styleToEnablePreviousButtonAndNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.ENABLE);
    setNextButtonStyling(buttonFunctionalityClasses.ENABLE);
  };

  const styleToDisablePreviousButtonAndDisableNextButton = () => {
    setPreviousButtonStyling(buttonFunctionalityClasses.DISABLE);
    setNextButtonStyling(buttonFunctionalityClasses.DISABLE);
  };

  /** Previous and Next button styling. */
  useEffect(() => {
    switch (checkpoint) {
      default:
        styleToEnablePreviousButtonAndNextButton();
        break;
    }
  }, [checkpoint]);

  const setPreviousCheckpoint = (currentCheckpoint = checkpoint) => {
    switch (currentCheckpoint) {
      default:
    }
  };

  const setNextCheckpoint = useCallback(
    (currentCheckpoint = checkpoint) => {
      switch (currentCheckpoint) {
        default:
      }
    },
    [checkpoint]
  );

  const handlePreviousClick = () => {
    // If the DISABLE class is applied, do not allow previous click.
    if (previousButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    setPreviousCheckpoint();
  };

  const handleNextClick = useCallback(async () => {
    // If the DISABLE class is applied, do not allow next click.
    if (nextButtonStyling === buttonFunctionalityClasses.DISABLE) {
      return;
    }
    if (checkpoint === ZYNC_UP_URL) {
      setIsLoading(true);

      try {
        const zyncUpId = zyncUpUrl.split('/').pop();
        const response = await axios.get(
          `${PROCESSOR_BASE_URL}/asset?groupId=${zyncUpId}&processorId=ZYNC_UP`
        );

        if (response?.status === 200) {
          const asset = response?.data?.result;
          const isSameWorkspace = workspaceId === asset?.workspaceId;

          if (isSameWorkspace) {
            setZyncUpError("ZyncUp Session can't be in the same workspace");
            setIsLoading(false);
            return;
          }

          const data = asset?.data;
          const { seekerDetails } = data || {};

          if (!seekerDetails) {
            setZyncUpError('Invalid ZyncUp Session');
            setIsLoading(false);
            return;
          }

          createNewEvent(data, zyncUpId);
        }
      } catch (error) {
        setZyncUpError('Internal server error, please try again');
        setIsLoading(false);
      }
    } else {
      setNextCheckpoint();
    }
  }, [
    ZYNC_UP_URL,
    checkpoint,
    createNewEvent,
    nextButtonStyling,
    setNextCheckpoint,
    workspaceId,
    zyncUpUrl,
  ]);

  /** Returns true/false depending on if the checkpoint in state comes before the argument, checkpoint. */
  const isBeforeCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    const stateCheckpoint = checkpoint;
    if (currentCheckpoint === stateCheckpoint) {
      return false;
    }
    for (let checkpoint in checkpointsData) {
      // found the state checkpoint first
      if (stateCheckpoint === checkpointsData[checkpoint]) {
        return true;
      }
      // found the current checkpoint first
      if (currentCheckpoint === checkpointsData[checkpoint]) {
        return false;
      }
    }
  };

  /** Returns true/false depending on if the checkpoint in state is at the argument, checkpoint. */
  const isAtCheckpoint = (currentCheckpointName) => {
    const currentCheckpoint = checkpointsData[currentCheckpointName];
    return currentCheckpoint === checkpoint;
  };

  const ProgressStates = {
    BEFORE: 'BEFORE',
    CURRENT: 'CURRENT',
    AFTER: 'AFTER',
  };

  /** Every checkpoint is looped over to determine its relationship to the checkpoint in state.
   *  This is used to determine the color & attributes that the currentCheckpoint should contain.
   *  If the checkpoint in state comes before currentCheckpoint, then its state is BEFORE
   *  If the checkpoint in state is at currentCheckpoint, then its state is CURRENT
   *  If the checkpoint in state is after currentCheckpoint, then its state is AFTER
   */
  const getProgressState = (currentCheckpoint) => {
    const isBefore = isBeforeCheckpoint(currentCheckpoint);
    const isAt = isAtCheckpoint(currentCheckpoint);
    if (isBefore) {
      return ProgressStates.BEFORE;
    }
    if (isAt) {
      return ProgressStates.CURRENT;
    }
    return ProgressStates.AFTER;
  };

  const areDatesValid = validateTimes(
    scheduledEvent.currentDate,
    scheduledEvent.startDate,
    scheduledEvent.endDate
  );

  return {
    brandKit,
    checkpoint,
    checkpointsData,
    isLoading,

    getProgressState,

    handlePreviousClick,
    handleNextClick,

    previousButtonStyling,
    nextButtonStyling,

    styleToDisablePreviousButtonAndDisableNextButton,
    styleToEnablePreviousButtonAndNextButton,

    areDatesValid,

    zyncUpUrl,
    setZyncUpUrl,

    zyncUpError,
  };
};
