import { capitalizeFirstLetter } from '../../components/Highlights';
import { Button } from '../../components/common/Button';
import { ModalBody, ModalTitle, ModalWindow } from '../../components/Modal';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import classNames from '../../helper/classNames';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create();

const retryDelay = (retryCount) => {
  return 5000 * retryCount;
};

axiosRetry(axiosInstance, {
  retries: 5,
  retryDelay,
  shouldResetTimeout: true,
  retryCondition: (error) =>
    axiosRetry.isNetworkOrIdempotentRequestError(error),
});

const checkIsPodcast = (workspace = undefined) => {
  return workspace?.workspacePlanCache?.workspaceType === 'podcast';
};
const CreateWorkspaceModal = ({ isOpen, onClose, onConfirm, initialError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleConfirm = async () => {
    setError('');
    setIsLoading(true);

    if (isLoading) {
      return;
    }

    try {
      const result = await onConfirm();

      if (result?.error) {
        setError(result.error);
        setIsLoading(false);
      } else {
        onClose();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Create new workspace</ModalTitle>
      <ModalBody>
        <div className="flex gap-4 items-center justify-end">
          {isLoading ? null : (
            <Button onClick={onClose}>
              <span className="text-sm font-medium">Cancel</span>
            </Button>
          )}
          <Button
            color={Button.colors.PURPLE}
            onClick={handleConfirm}
            state={
              isLoading
                ? Button.states.LOADING
                : initialError
                ? Button.states.DISABLED
                : Button.states.DEFAULT
            }
          >
            <span className="text-sm font-medium">Submit</span>
          </Button>
        </div>
        {error || initialError ? (
          <p className="text-xxs text-right text-red font-medium">
            {error || initialError}
          </p>
        ) : null}
      </ModalBody>
    </ModalWindow>
  );
};

const ConvertToPaidPlanModal = ({
  isOpen,
  onClose,
  onSuccess,
  workspaceId,
  workspaceName,
  ownerId,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleConvertToPaid = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const { data } = await axiosInstance.post('/api/stripe/convert-to-paid', {
        workspaceId,
        userId: ownerId,
      });
      if (data.error) {
        setErrorMessage(data.error);
      }

      if (onSuccess) {
        onSuccess(workspaceId);
      }
    } catch (error) {
      if (error) {
        setErrorMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Upgrade to Paid</ModalTitle>
      <ModalBody>
        <p className="text-sm text-blue-dark mb-4">
          Your workspace <strong>{workspaceName}</strong> ({workspaceId}) will
          be converted to a paid workspace with a monthly recurring payment
        </p>
        <div className="flex gap-4 items-center justify-end">
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.WHITE}
            onClick={onClose}
          >
            <span className="text-sm font-medium">Cancel</span>
          </Button>
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.PURPLE}
            state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
            onClick={handleConvertToPaid}
          >
            <span className="text-sm font-medium">Confirm</span>
          </Button>
        </div>
        {errorMessage ? (
          <p className="text-right text-red font-medium text-sm pt-2">
            {errorMessage}
          </p>
        ) : null}
      </ModalBody>
    </ModalWindow>
  );
};

const DeactivatePlanModal = ({ isOpen, onClose, onSuccess, workspaceId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivate = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const { data } = await axiosInstance.post(
        '/api/stripe/cancel-subscription-at-period-end',
        {
          workspaceId,
          cancelAtPeriodEnd: true,
        }
      );
      if (data.error) {
        setErrorMessage(data.error);
      }

      if (onSuccess) {
        onSuccess(workspaceId);
      }
    } catch (error) {
      if (error) {
        setErrorMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Deactivate Subscription</ModalTitle>
      <ModalBody>
        <p className="text-sm text-blue-dark mb-4">
          This will cancel your recurring subscription at the end of the current
          subscription cycle. Are you sure you want to cancel subscription?
        </p>
        <div className="flex gap-4 items-center justify-end">
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.WHITE}
            onClick={onClose}
          >
            <span className="text-sm font-medium">No</span>
          </Button>
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.PURPLE}
            state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
            onClick={handleDeactivate}
          >
            <span className="text-sm font-medium">Yes</span>
          </Button>
        </div>
        {errorMessage ? (
          <p className="text-right text-red font-medium text-sm pt-2">
            {errorMessage}
          </p>
        ) : null}
      </ModalBody>
    </ModalWindow>
  );
};

const ReactivatePlanModal = ({ isOpen, onClose, onSuccess, workspaceId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleReactivate = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const { data } = await axiosInstance.post(
        '/api/stripe/cancel-subscription-at-period-end',
        {
          workspaceId,
          cancelAtPeriodEnd: false,
        }
      );
      if (data.error) {
        setErrorMessage(data.error);
      }

      if (onSuccess) {
        onSuccess(workspaceId);
      }
    } catch (error) {
      if (error) {
        setErrorMessage(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Reactivate Subscription</ModalTitle>
      <ModalBody>
        <p className="text-sm text-blue-dark mb-4">
          This action will reactivate your previously canceled recurring
          subscription. You will be billed at the start of your next billing
          cycle. Are you sure you want to reactivate your subscription?
        </p>
        <div className="flex gap-4 items-center justify-end">
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.WHITE}
            onClick={onClose}
          >
            <span className="text-sm font-medium">No</span>
          </Button>
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.PURPLE}
            state={isLoading ? Button.states.LOADING : Button.states.DEFAULT}
            onClick={handleReactivate}
          >
            <span className="text-sm font-medium">Yes</span>
          </Button>
        </div>
        {errorMessage ? (
          <p className="text-right text-red font-medium text-sm pt-2">
            {errorMessage}
          </p>
        ) : null}
      </ModalBody>
    </ModalWindow>
  );
};

const SupportModal = ({ isOpen, onClose }) => {
  return (
    <ModalWindow
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      bg="TRANSPARENT"
      withBackgroundBlur={false}
      boxShadow={200}
    >
      <ModalTitle>Contact Support</ModalTitle>
      <ModalBody>
        <p className="text-sm text-blue-dark mb-4">
          Please contact us at{' '}
          <a href="mailto: support@zync.ai" className="text-blue text-sm">
            support@zync.ai
          </a>{' '}
          to perform this action
        </p>
        <div className="flex gap-4 items-center justify-end">
          <Button
            padding={Button.padding.SMALL}
            color={Button.colors.PURPLE}
            onClick={onClose}
          >
            <span className="text-sm font-medium">OK</span>
          </Button>
        </div>
      </ModalBody>
    </ModalWindow>
  );
};

let timeout = null;

export const WorkspaceTable = ({
  workspaces = [],
  initialError,
  handleCreateWorkspace,
  canCreateNewWorkspace,
  refreshWorkspaces,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const [updatedWorkspaceAsync, setUpdatedWorkspaceAsync] = useState('');

  const [convertToPaidModal, setConvertToPaidModal] = useState(null);
  const [deactivateModal, setDeactivateModal] = useState(null);
  const [reactivateModal, setReactivateModal] = useState(null);

  useEffect(() => {
    if (!updatedWorkspaceAsync) {
      return window.clearTimeout(timeout);
    }

    timeout = window.setTimeout(async () => {
      await refreshWorkspaces();
      setUpdatedWorkspaceAsync(null);
    }, 5_000);
  }, [updatedWorkspaceAsync, refreshWorkspaces]);

  return (
    <>
      {isConfirmationModalOpen ? (
        <CreateWorkspaceModal
          initialError={initialError}
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={handleCreateWorkspace}
        />
      ) : null}
      {isSupportModalOpen ? (
        <SupportModal
          isOpen={isSupportModalOpen}
          onClose={() => setIsSupportModalOpen(false)}
        />
      ) : null}
      {convertToPaidModal ? (
        <ConvertToPaidPlanModal
          isOpen={!!convertToPaidModal}
          onClose={() => setConvertToPaidModal(null)}
          workspaceId={convertToPaidModal.workspaceId}
          workspaceName={convertToPaidModal.workspaceName}
          ownerId={convertToPaidModal.ownerId}
          onSuccess={() => {
            setConvertToPaidModal(null);
            setUpdatedWorkspaceAsync(convertToPaidModal.workspaceId);
          }}
        />
      ) : null}
      {deactivateModal ? (
        <DeactivatePlanModal
          isOpen={!!deactivateModal}
          onClose={() => setDeactivateModal(null)}
          workspaceId={deactivateModal.workspaceId}
          workspaceName={deactivateModal.workspaceName}
          ownerId={deactivateModal.ownerId}
          onSuccess={() => {
            setDeactivateModal(null);
            setUpdatedWorkspaceAsync(deactivateModal.workspaceId);
          }}
        />
      ) : null}
      {reactivateModal ? (
        <ReactivatePlanModal
          isOpen={!!reactivateModal}
          onClose={() => setReactivateModal(null)}
          workspaceId={reactivateModal.workspaceId}
          workspaceName={reactivateModal.workspaceName}
          ownerId={reactivateModal.ownerId}
          onSuccess={() => {
            setReactivateModal(null);
            setUpdatedWorkspaceAsync(reactivateModal.workspaceId);
          }}
        />
      ) : null}
      <div className="px-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-8 border border-[#eee] rounded-lg">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">
              Workspaces
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the workspaces in your organization.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none group relative">
            <Button
              state={
                canCreateNewWorkspace
                  ? Button.states.DEFAULT
                  : Button.states.DISABLED
              }
              color={Button.colors.PURPLE}
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              <span className="text-sm font-medium">
                Create New Trial Workspace
              </span>
            </Button>
            {!canCreateNewWorkspace ? (
              <div className="opacity-0 absolute group-hover:opacity-100 text-xs font-medium text-gray-900 bg-white rounded-md text-center border border-blue-gray/10 mt-2 p-2 shadow-400">
                You need to setup payment method first
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-[#aaa]">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-blue-dark sm:pl-0"
                    >
                      Workspace
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-blue-dark"
                    >
                      Payment Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-right text-sm font-semibold text-blue-dark"
                    >
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-[#ddd]">
                  {workspaces.map((workspace) => (
                    <tr key={workspace.workspaceId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-dark sm:pl-0">
                        <p className="text-[#ACACAC] text-xxs font-[courier]">
                          {workspace.workspaceId}
                        </p>
                        <Link
                          to={`/${
                            checkIsPodcast(workspace) ? '' : 'solo'
                          }?workspaceId=${workspace.workspaceId}`}
                          className="text-base text-blue underline"
                        >
                          {workspace.name}
                        </Link>
                        {checkIsPodcast(workspace) ? null : (
                          <div className="flex gap-1">
                            <p className="font-medium text-sm">
                              {workspace.soloSettings?.defaultSpeaker
                                ?.fullName || 'Creator not set'}
                            </p>

                            <Link
                              to={`/workspace/workspace-solo-settings?workspaceId=${workspace.workspaceId}`}
                              className="text-[8px] text-blue underline font-medium"
                            >
                              Update
                            </Link>
                          </div>
                        )}
                      </td>
                      {workspace.workspaceId === updatedWorkspaceAsync ? (
                        <td className="px-3 py-4 text-center">
                          <LoadingSpinner width="30px" height="30px" />
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {!workspace.isTrial && workspace.plan !== 'free' ? (
                            <div>
                              <p>
                                {workspace?.workspacePlanCache
                                  ?.cancelAtPeriodEnd
                                  ? 'Canceled'
                                  : 'Active'}{' '}
                                Subscription{' '}
                              </p>
                              <p className="text-[#999999] text-xxs font-[courier]">
                                {workspace?.workspacePlanCache
                                  ?.cancelAtPeriodEnd
                                  ? 'Ends at'
                                  : 'Next'}
                                :{' '}
                                {moment(
                                  workspace?.workspacePlanCache
                                    ?.currentPeriodEnd
                                ).format('MMM DD, YYYY')}
                              </p>
                            </div>
                          ) : null}
                          {workspace.isTrial ? (
                            <div>
                              <p>Trial</p>
                              <p className="text-[#999999] text-xxs font-[courier]">
                                Ends:{' '}
                                {moment(
                                  workspace?.workspacePlanCache?.trialEnd
                                ).format('MMM DD, YYYY')}
                              </p>
                            </div>
                          ) : null}
                          {workspace.plan === 'free'
                            ? capitalizeFirstLetter(workspace.plan)
                            : null}
                        </td>
                      )}
                      {workspace.workspaceId === updatedWorkspaceAsync ? (
                        <td className="px-3 py-4 text-center">
                          <LoadingSpinner
                            width="30px"
                            height="30px"
                            className="ml-auto"
                          />
                        </td>
                      ) : (
                        <td className="relative whitespace-nowrap py-8 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex justify-end">
                          {workspace.isTrial ? (
                            <Button
                              color={Button.colors.WHITE_LITE}
                              padding={Button.padding.SMALL}
                              state={
                                canCreateNewWorkspace
                                  ? Button.states.DEFAULT
                                  : Button.states.DISABLED
                              }
                              onClick={() =>
                                setConvertToPaidModal({
                                  workspaceName: workspace.name,
                                  workspaceId: workspace.workspaceId,
                                  ownerId: workspace.ownerId,
                                })
                              }
                            >
                              <span className="text-cyan text-sm">
                                Convert to Paid
                              </span>
                              <span className="sr-only">
                                {workspace.workspaceName}
                              </span>
                            </Button>
                          ) : null}
                          {workspace.plan === 'free' ? (
                            <Button
                              color={Button.colors.WHITE_LITE}
                              padding={Button.padding.SMALL}
                              state={
                                canCreateNewWorkspace
                                  ? Button.states.DEFAULT
                                  : Button.states.DISABLED
                              }
                              onClick={() =>
                                setConvertToPaidModal({
                                  workspaceName: workspace.name,
                                  workspaceId: workspace.workspaceId,
                                  ownerId: workspace.ownerId,
                                })
                              }
                            >
                              <span className="text-orange-light text-sm">
                                Reactivate
                              </span>
                              <span className="sr-only">
                                {workspace.workspaceName}
                              </span>
                            </Button>
                          ) : null}
                          {workspace.plan !== 'free' && !workspace.isTrial ? (
                            <Button
                              color={Button.colors.WHITE_LITE}
                              padding={Button.padding.SMALL}
                              onClick={() =>
                                workspace?.workspacePlanCache?.cancelAtPeriodEnd
                                  ? setReactivateModal({
                                      workspaceName: workspace.name,
                                      workspaceId: workspace.workspaceId,
                                      ownerId: workspace.ownerId,
                                    })
                                  : setDeactivateModal({
                                      workspaceName: workspace.name,
                                      workspaceId: workspace.workspaceId,
                                      ownerId: workspace.ownerId,
                                    })
                              }
                            >
                              <span
                                className={classNames(
                                  workspace?.workspacePlanCache
                                    ?.cancelAtPeriodEnd
                                    ? 'text-cyan'
                                    : 'text-red',
                                  'text-sm'
                                )}
                              >
                                {workspace?.workspacePlanCache
                                  ?.cancelAtPeriodEnd
                                  ? 'Resume Subscription'
                                  : 'Cancel Subscription'}
                              </span>
                              <span className="sr-only">
                                {workspace.workspaceName}
                              </span>
                            </Button>
                          ) : null}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
