import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import zyncRegistrationSources from 'zync-common/hubspot/zyncRegistrationSources';
import { completeRegistration, login, register } from '../auth';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { getUserId, isMobile, isProductionServer } from '../helper';
import mixpanel from 'mixpanel-browser';
import { fromError, logerror } from '../helper/contextualLogger';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { errorMessages } from '../hooks/useUserAppEntry';
import {
  Modal,
  ModalBody,
  ModalTitle,
  ModalWindow,
  ModalButton,
} from './Modal';
import { TextInput } from './Input/TextInput';
import classNames from '../helper/classNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { LogoSpinnerFullCentered } from './LogoSpinner';
import { FuturisticBackground } from './FuturisticBackground';
import { exponentialFetchRetry } from '../helper/exponentialFetchRetry';
import { fetchUserApi, resendEmailVerificationAPI } from '../helper/api';
import { LoadingSpinnerFullCentered } from './LoadingSpinner';
import { HeavyFullViewBackdropBlur } from './HeavyFullViewBackdropBlur';
import styles from './SignOn/SignOn.module.css';

const logo = getStaticAssetUrl('logo_text.png');
const logoNoPadding = getStaticAssetUrl('zync-logo-no-padding.png');
const mobileSignInArt = getStaticAssetUrl('signin-mobile-art.png');
const mobileRegisterArt = getStaticAssetUrl('register-mobile-art.png');
const mobileForgotPasswordArt = getStaticAssetUrl(
  'forgot-password-mobile-art.png'
);
const mobileForgotPasswordEmailArt = getStaticAssetUrl(
  'forgot-password-email-mobile-art.png'
);

const SignOnHeader = () => {
  return (
    <div className="flex flex-col z-[2] items-center gap-2 pt-[88px] md:pt-0">
      <div className="h-11 flex justify-center items-center">
        <img
          className="object-cover w-[100px] md:w-[120px]"
          src={logo}
          alt="zync logo"
        />
      </div>
    </div>
  );
};

const SignOnContainer = ({ children, pageMode, setPageMode }) => {
  return (
    <div className="z-[2] mt-2 w-96 flex items-stretch mb-4 md:mb-0">
      <Modal size={Modal.size.sm} boxShadow={Modal.boxShadow['3xl']}>
        {(pageMode === 'register' || pageMode === 'signin') && (
          <div className="flex flex-row pt-6 px-6 md:pt-8 md:px-8 w-full gap-6">
            <button
              className={classNames(
                'font-semibold text-xl cursor-pointer bg-transparent border-0 p-0',
                pageMode === 'register'
                  ? 'underline decoration-purple decoration-solid decoration-2 text-purple'
                  : 'text-light-gray'
              )}
              onClick={() => setPageMode('register')}
            >
              Sign up
            </button>
            <button
              className={classNames(
                'font-semibold text-xl cursor-pointer bg-transparent border-0 p-0',
                pageMode === 'signin'
                  ? 'underline decoration-purple decoration-solid decoration-2 text-purple'
                  : 'text-light-gray'
              )}
              onClick={() => setPageMode('signin')}
            >
              Sign in
            </button>
          </div>
        )}
        <ModalBody>{children}</ModalBody>
        {(pageMode === 'register' || pageMode === 'signup') && (
          <p className="text-blue-gray text-sm px-6 pb-6 font-normal m-0">
            By registering, you agree to our{' '}
            <a
              href="/tos"
              className="underline decoration-purple decoration-solid decoration-1 text-purple text-sm"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="/privacy"
              className="underline decoration-purple decoration-solid decoration-1 text-purple text-sm"
            >
              Privacy Policy
            </a>
          </p>
        )}
        {pageMode === 'signin' && (
          <p className="text-blue-gray text-sm px-6 pb-6 font-normal m-0">
            Not registered?
            <button
              onClick={() => setPageMode('register')}
              className="underline decoration-purple decoration-solid decoration-1 text-purple text-sm pl-1 inline-block"
            >
              Register
            </button>
          </p>
        )}
      </Modal>
    </div>
  );
};

export const AuthModal = ({
  authMode,
  closeAuth,
  fixedEmailAddress,
  allowJoinAsGuest,
  invitationCode,
  errorMessage = '',
  redirectToPortalPageAfterLogIn = true,
}) => {
  const [pageMode, setPageMode] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageMode(authMode);
  }, [authMode]);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        closeAuth();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [closeAuth]);

  if (isMobile) {
    return (
      <div
        className={`w-full flex flex-col items-center px-[10%] justify-center ${styles['mobile-container']} overflow-hidden`}
      >
        <img src={logoNoPadding} alt="" className="mb-5" />
        <SignOn
          pageMode={pageMode}
          closeAuth={closeAuth}
          setPageMode={setPageMode}
          fixedEmailAddress={fixedEmailAddress}
          allowJoinAsGuest={allowJoinAsGuest}
          invitationCode={invitationCode}
          errorMessage={errorMessage}
          loading={loading}
          setLoading={setLoading}
          redirectToPortalPageAfterLogIn={redirectToPortalPageAfterLogIn}
        />
      </div>
    );
  }

  return (
    <div className="md:fixed top-0 right-0 bottom-0 left-0 gap-4 md:gap-2 flex flex-col items-center md:justify-center opacity-100">
      <HeavyFullViewBackdropBlur />
      <FuturisticBackground topGradientOpacity="medium" />
      <SignOnHeader />
      <SignOnContainer pageMode={pageMode} setPageMode={setPageMode}>
        <SignOn
          pageMode={pageMode}
          closeAuth={closeAuth}
          setPageMode={setPageMode}
          fixedEmailAddress={fixedEmailAddress}
          allowJoinAsGuest={allowJoinAsGuest}
          invitationCode={invitationCode}
          errorMessage={errorMessage}
          loading={loading}
          setLoading={setLoading}
          redirectToPortalPageAfterLogIn={redirectToPortalPageAfterLogIn}
        />
      </SignOnContainer>
    </div>
  );
};

const emailContainsDisallowedDomain = (emailAddress) => {
  const disallowedDomains = ['gmail.com', 'hotmail.com', 'yahoo.'];
  for (let i = 0; i < disallowedDomains.length; i++) {
    if (emailAddress.includes(disallowedDomains[i])) {
      return true;
    }
  }
  return false;
};

const Register = ({ loading, setLoading, allowJoinAsGuest, setPageMode }) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const domainParam = queryParams.get('domain');
  const onlyEnterpriseDomains = domainParam === 'enterprise';
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const redirectUrl = window.location.pathname.split('?')[0];
  const onRegister = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    if (!emailAddress || emailAddress.length === 0) {
      setLoading(false);
      setError('Sign up requires email address.');
      return;
    }
    if (onlyEnterpriseDomains && emailContainsDisallowedDomain(emailAddress)) {
      setLoading(false);
      setError('Please register with your company address');
      return;
    }
    let affiliateData = undefined;

    if (window.Rewardful?.referral) {
      affiliateData = {
        provider: 'rewardful',
        referral: window.Rewardful.referral,
        Affiliate: window.Rewardful.affiliate.name,
        Campaign: window.Rewardful.campaign.name,
        Link: window.Rewardful.affiliate.token,
      };
    }

    try {
      const { error, status } = await register({
        emailAddress,
        redirectUrl,
        registrationSourceId: zyncRegistrationSources.STANDARD_SIGN_UP,
        affiliateData,
      });
      if (status === 200) {
        setRegistrationComplete(true);
        setEmailAddress('');
        history.replace(`/thank-you?email=${encodeURIComponent(emailAddress)}`);
      } else if (status === 106) {
        setError(errorMessages.networkError);
      } else if (status === 500) {
        setError('Internal error');
        logerror({
          userId: emailAddress,
          message: 'Error attempting to register user: ' + error,
        });
      } else {
        setError(error);
      }
    } catch (err) {
      logerror({
        userId: emailAddress,
        message: 'Caught exception attempting to register user: ' + err.message,
        stacktrace: err.stack || err,
      });
      setError('Internal error');
    }
    setLoading(false);
  };

  if (isMobile) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <img
          src={mobileRegisterArt}
          alt=""
          className="max-h-[283px] h-[20vh]"
        />
        <span className="max-h-[40px] h-[3vh]"></span>
        <span className="text-[#303364] text-base phone-h:text-2xl font-roboto font-medium text-center">
          Create Your Zync Account
        </span>
        <input
          className={classNames(
            ' placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full rounded-none px-2',
            error
              ? 'bg-[#FF00000D] text-[#FF0000] border-[#FF0000]'
              : 'bg-transparent text-black border-[#3C417B]'
          )}
          placeholder="Email ID"
          value={emailAddress}
          autoFocus
          label="Email"
          type="email"
          onChange={(event) =>
            setEmailAddress(event.target.value?.toLowerCase())
          }
        />
        {error && (
          <span className="text-[#FF0000] font-montserrat font-medium text-xs text-right w-full">
            {error}
          </span>
        )}
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-montserrat font-bold text-white bg-[#5C41FF] rounded-2xl text-xl w-full py-3"
          onClick={onRegister}
        >
          Register
        </button>
        <span className="max-h-[36px] h-[3vh]"></span>
        <div className="flex gap-2 w-full justify-center items-center">
          <span className="bg-[#6C6C6C] h-[0.5px] w-full"></span>
          <span className="w-[130px] font-roboto text-[#888AA4] text-base shrink-0 text-center">
            Have an Account
          </span>
          <span className="bg-[#6C6C6C] h-[0.5px] w-full"></span>
        </div>
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-montserrat font-bold text-white bg-[#2E2E2E] rounded-2xl text-xl w-full py-3"
          onClick={() => setPageMode('signin')}
          type="button"
        >
          Sign In
        </button>
        <span className="max-h-[32px] h-[4vh]"></span>
        <p className="text-[#617182] text-xs font-medium m-0 text-center self-end">
          By registering, you agree to our{' '}
          <a href="/tos" className=" text-[#5C41FF] text-xs">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy" className=" text-[#5C41FF] text-xs">
            Privacy Policy
          </a>
        </p>
      </div>
    );
  }

  return (
    <>
      {!registrationComplete && (
        <form onSubmit={onRegister} className="relative">
          {!registrationComplete && (
            <>
              <div className="text-blue-gray text-sm font-normal pt-2 mb-6 -mt-5">
                Create account to start using Zync
              </div>
              <TextInput
                autoFocus
                value={emailAddress}
                onChange={(event, value) =>
                  setEmailAddress(value?.toLowerCase())
                }
                label="Email"
                placeholder="name@example.com"
                type="email"
              />
              <p className="text-red font-normal text-sm p-0 mt-0 mb-8">
                {error}
              </p>
              {!loading && (
                <div className="w-full flex h-12 my-8">
                  <ModalButton
                    type="submit"
                    buttonText={'Register'}
                    disabled={loading}
                  />
                </div>
              )}
              {loading && (
                <div className="w-full my-8">
                  <LogoSpinnerFullCentered />
                </div>
              )}
              {!!allowJoinAsGuest && !loading && (
                <div
                  className={classNames(
                    'w-full flex h-12 mb-8',
                    loading ? 'mt-0' : '-mt-5'
                  )}
                >
                  <ModalButton
                    type="button"
                    buttonText="Join as Guest"
                    handleOnClick={() => setPageMode('joinasguest')}
                  />
                </div>
              )}
            </>
          )}
        </form>
      )}
      {registrationComplete && (
        <ModalWindow
          size={Modal.size.md}
          zyncLogo
          showCancel={false}
          className="text-center"
        >
          <ModalTitle>Thanks for registering</ModalTitle>
          <ModalBody>
            <p className="text-blue-gray text-sm">
              We have emailed you instructions to complete the registration
              process and get started.
            </p>
          </ModalBody>
        </ModalWindow>
      )}
    </>
  );
};

const SignUp = ({
  fixedEmailAddress,
  errorMessage = '',
  invitationCode,
  loading,
  setLoading,
}) => {
  const queryParams = new URLSearchParams(useLocation().search);
  let redirectUrl = queryParams.get('redirectUrl') || '/portal';
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState(fixedEmailAddress || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(errorMessage);
  const dispatch = useDispatch();
  const [registrationComplete, setRegistrationComplete] = useState(false);

  const onSignUp = async (e) => {
    e.preventDefault();
    setError('');
    if (!fullName || !emailAddress || !password) {
      setError('Sign up requires your name, email address and a password.');
      return;
    }

    setLoading(true);

    try {
      const { user, token, error, status } = await completeRegistration({
        emailAddress,
        password,
        userName: fullName,
        registrationCode: invitationCode,
      });
      if (status === 200) {
        mixpanel.alias(user.userId);
        mixpanel.track('Onboarding: Completed Registration', {
          distinct_id: user.userId,
        });
        const action = { type: 'REGISTER_SUCCESS', user, token };
        dispatch(action);
        setRegistrationComplete(true);
        setEmailAddress('');
        setPassword('');
        setFullName('');
      } else if (status === 106) {
        setError(errorMessages.networkError);
      } else if (status === 500) {
        logerror({
          userId: emailAddress,
          message: 'Registration completion failed with error:' + error,
        });
      } else {
        setError(error);
      }
    } catch (err) {
      logerror({
        userId: emailAddress,
        message:
          'Caught exception attempting to complete registration for user: ' +
          err.message,
        stacktrace: err.stack || err,
      });
      setError('Internal error');
    }
    setLoading(false);
  };

  if (registrationComplete) {
    if (isProductionServer() && window.gr) {
      try {
        window.gr('track', 'conversion', { email: emailAddress });
      } catch (error) {
        logerror(fromError(error));
      }
    }
    const urlObj = new URL(redirectUrl, window.location.origin);
    urlObj.searchParams.set('intent', 'signin');
    redirectUrl = urlObj.pathname + urlObj.search;
    return <Redirect to={`${redirectUrl}`} />;
  }

  if (isMobile) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <img
          src={mobileRegisterArt}
          alt=""
          className="max-h-[283px] h-[20vh]"
        />
        <span className="max-h-[40px] h-[3vh]"></span>
        <span className="text-[#5C41FF] text-xl phone-h:text-4xl font-roboto text-left w-full">
          Sign Up
        </span>
        <span className="max-h-[16px] h-[1vh]"></span>
        <span className="text-[#555565] text-xs phone-h:text-base font-medium font-roboto w-full">
          Please complete the registration process
        </span>
        <input
          className="placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full bg-transparent text-black border-[#3C417B] rounded-none px-2"
          placeholder="Full Name"
          value={fullName}
          autoFocus
          label="Full name"
          onChange={(event) => setFullName(event.target.value)}
        />
        <input
          className="placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full bg-transparent text-black border-[#3C417B] rounded-none px-2"
          placeholder="Email ID"
          value={emailAddress}
          disabled={!!fixedEmailAddress}
          label="Email"
          type="email"
          onChange={(event) =>
            setEmailAddress(event.target.value?.toLowerCase())
          }
        />
        <input
          className="placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full bg-transparent text-black border-[#3C417B] rounded-none px-2"
          placeholder="Password"
          value={password}
          label="Password"
          type="password"
          onChange={(event) => setPassword(event.target.value)}
        />
        {error && (
          <span className="text-[#FF0000] font-montserrat font-medium text-xs text-right w-full">
            {error}
          </span>
        )}
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-montserrat font-bold text-white bg-[#5C41FF] rounded-2xl text-xl w-full py-3"
          onClick={onSignUp}
        >
          Create Account
        </button>
        <span className="max-h-[32px] h-[4vh]"></span>
        <p className="text-[#617182] text-xs font-medium m-0 text-center self-end">
          By registering, you agree to our{' '}
          <a href="/tos" className=" text-[#5C41FF] text-xs">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy" className=" text-[#5C41FF] text-xs">
            Privacy Policy
          </a>
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={onSignUp}>
      <div className="text-purple font-semibold text-xl pt-8 pb-2 -mt-8">
        Sign up
      </div>
      <div className="text-blue-gray font-normal text-sm mb-8">
        Please complete your registration to get started
      </div>
      <TextInput
        autoFocus
        value={fullName}
        onChange={(event, value) => setFullName(value)}
        label="Full name"
      />
      <TextInput
        value={emailAddress}
        onChange={(event, value) => setEmailAddress(value?.toLowerCase())}
        label="Email"
        disabled={!!fixedEmailAddress}
        type="email"
      />
      <TextInput
        value={password}
        onChange={(event, value) => setPassword(value)}
        label="Password"
        type="password"
        placeholder="Enter Password"
      />
      <p className="text-red font-normal text-sm p-0 mt-0">{error}</p>
      <div className="w-full flex h-12 my-8">
        <ModalButton
          type="submit"
          buttonText="Create Account"
          disabled={loading}
        />
      </div>
      {loading && (
        <div className="w-full my-8">
          <LogoSpinnerFullCentered />
        </div>
      )}
    </form>
  );
};

const onLogin = async (
  emailAddress,
  password,
  dispatch,
  setError,
  setLoading
) => {
  try {
    const { user, token, error, status } = await login({
      emailAddress,
      password,
    });
    if (status === 200) {
      const action = { type: 'LOGIN_SUCCESS', user, token };
      mixpanel.identify(user.userId);
      dispatch(action);
      return true;
    } else if (status === 106) {
      logerror({
        userId: emailAddress,
        message: 'Error while attempting to login: ' + error,
      });
      dispatch({ type: 'LOGIN_FAILURE', error });
      setError(errorMessages.networkError);
      setLoading(false);
    } else if (status === 500) {
      logerror({
        userId: emailAddress,
        message: 'Error while attempting to login: ' + error,
      });
      dispatch({ type: 'LOGIN_FAILURE', error: 'Internal error' });
      setError('Internal error');
      setLoading(false);
    } else {
      dispatch({ type: 'LOGIN_FAILURE', error });
      setError(error);
      setLoading(false);
    }
    return false;
  } catch (err) {
    logerror({
      userId: emailAddress,
      message: 'Caught exception attempting to login user: ' + err.message,
      stacktrace: err.stack || err,
    });
    dispatch({ type: 'LOGIN_FAILURE', error: 'Internal error' });
    setError('Internal error');
    return false;
  }
};

const LogIn = ({
  setPageMode,
  fixedEmailAddress,
  allowJoinAsGuest,
  errorMessage = '',
  loading,
  setLoading,
  redirectToPortalPageAfterLogIn,
}) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const emailParam = queryParams.get('email');

  const [emailAddress, setEmailAddress] = useState(
    fixedEmailAddress ? fixedEmailAddress : emailParam || ''
  );
  const [password, setPassword] = useState('');
  const [error, setError] = useState(errorMessage);
  const [redirectToPortalPage, setRedirectToPortalPage] = useState(false);
  const dispatch = useDispatch();

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const isLoggedIn = await onLogin(
      emailAddress,
      password,
      dispatch,
      setError,
      setLoading
    );
    if (isLoggedIn && redirectToPortalPageAfterLogIn) {
      setRedirectToPortalPage(true);
    }
  };

  if (redirectToPortalPage) {
    return <Redirect to="/portal" />;
  }

  if (isMobile) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <img src={mobileSignInArt} alt="" className="max-h-[200px] h-[25vh]" />
        <span className="max-h-[40px] h-[3vh]"></span>
        <span className="text-[#303364] text-base phone-h:text-2xl font-roboto font-medium text-center">
          Login to your Zync Account
        </span>
        <input
          className={classNames(
            ' placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full rounded-none px-2',
            error
              ? 'bg-[#FF00000D] text-[#FF0000] border-[#FF0000]'
              : 'bg-transparent text-black border-[#3C417B]'
          )}
          placeholder="Email ID"
          disabled={!!fixedEmailAddress}
          value={emailAddress}
          autoFocus
          label="Email"
          type="email"
          onChange={(event) =>
            setEmailAddress(event.target.value?.toLowerCase())
          }
        />
        <input
          className={classNames(
            ' placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full rounded-none px-2',
            error
              ? 'bg-[#FF00000D] text-[#FF0000] border-[#FF0000]'
              : 'bg-transparent text-black border-[#3C417B]'
          )}
          placeholder="Password"
          value={password}
          label="Password"
          type="password"
          onChange={(event, value) => setPassword(event.target.value)}
        />
        {error && (
          <span className="text-[#FF0000] font-montserrat font-medium text-xs text-right w-full">
            {error}
          </span>
        )}
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-montserrat font-bold text-white bg-[#5C41FF] rounded-2xl text-xl w-full py-3"
          onClick={onSubmitLogin}
        >
          Sign In
        </button>
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-roboto font-medium text-[#303364] text-base"
          onClick={() => setPageMode('forgotpassword')}
          type="button"
        >
          Forgot Password
        </button>
        <span className="max-h-[24px] h-[2vh]"></span>
        <div className="flex gap-2 w-full justify-center items-center">
          <span className="bg-[#6C6C6C] h-[0.5px] w-full"></span>
          <span className="w-[119px] font-roboto text-[#888AA4] text-base shrink-0 text-center">
            New to Zync
          </span>
          <span className="bg-[#6C6C6C] h-[0.5px] w-full"></span>
        </div>
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-montserrat font-bold text-white bg-[#2E2E2E] rounded-2xl text-xl w-full py-3"
          onClick={() => setPageMode('register')}
          type="button"
        >
          Register
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmitLogin}>
      <div className="text-blue-gray font-normal text-sm pt-2 mb-6 -mt-5">
        Sign up and start using Zync
      </div>
      <TextInput
        autoFocus
        value={emailAddress}
        onChange={(event, value) => setEmailAddress(value?.toLowerCase())}
        label="Email"
        disabled={!!fixedEmailAddress}
        placeholder="name@example.com"
        type="email"
      />
      <TextInput
        value={password}
        onChange={(event, value) => setPassword(value)}
        label="Password"
        type="password"
        placeholder="Enter password"
      />
      <div className="w-full flex justify-end">
        <button
          className="text-purple border-0 p-0 underline decoration-solid decoration-1 decoration-purple bg-transparent text-xs font-medium cursor-pointer"
          onClick={() => setPageMode('forgotpassword')}
          type="button"
        >
          Forgot password?
        </button>
      </div>
      {error ? (
        <p className="text-red font-normal text-sm p-0 mt-0">{error}</p>
      ) : null}
      <div className="w-full flex h-12 my-8">
        <ModalButton type="submit" buttonText="Sign in" disabled={loading} />
      </div>
      {loading && (
        <div className="w-full my-8">
          <LogoSpinnerFullCentered />
        </div>
      )}
      {!!allowJoinAsGuest && (
        <div
          className={classNames(
            'w-full flex h-12 mb-8',
            loading ? 'mt-0' : '-mt-5'
          )}
        >
          <ModalButton
            type="button"
            buttonText="Join as Guest"
            handleOnClick={() => setPageMode('joinasguest')}
          />
        </div>
      )}
    </form>
  );
};

const JoinAsGuest = ({ setPageMode, loading, setLoading }) => {
  const [fullName, setFullName] = useState(
    localStorage.getItem('fullName') || ''
  );
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const createAndSetGuestUser = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const userName = fullName.trim();
    const email = emailAddress.trim();
    if (!userName) {
      setLoading(false);
      setError('Invalid full name entered.');
      return;
    }
    if (email && email.length > 0) {
      // If the user provided an email address, then best effort register them.
      try {
        const { status, error } = await register({
          emailAddress: email,
          userName,
          registrationSourceId: zyncRegistrationSources.MEETING_GUEST_ATTENDEE,
        });
        if (status === 500) {
          logerror({
            userId: email,
            message:
              'Error while attempting to register a user who has provided their email address while joining as guest: ' +
              error,
          });
        } else if (status === 106) {
          setError(errorMessages.networkError);
        }
      } catch (err) {
        logerror({
          userId: email,
          message:
            'Attempting to register a user who has provided their email address while joining as guest: ' +
            err.message,
          stacktrace: err.stack || err,
        });
      }
    }
    const userId = getUserId();
    localStorage.setItem('fullName', userName);
    dispatch({ type: 'SET_USER', user: { userId, userName, emailAddress } });
    setError('');
    setLoading(false);
  };

  return (
    <form onSubmit={createAndSetGuestUser}>
      <div className="text-purple font-semibold text-xl pt-8 pb-2 -mt-8">
        Join as guest
      </div>
      <TextInput
        autoFocus
        value={fullName}
        onChange={(event, value) => setFullName(value)}
        label="Full name"
      />
      <TextInput
        value={emailAddress}
        onChange={(event, value) => setEmailAddress(value)}
        label="Email"
        type="email"
      />
      <p className="text-red font-normal text-sm p-0 mt-0">{error}</p>
      <div className="w-full flex h-12 my-8">
        <ModalButton type="submit" buttonText="Join" disabled={loading} />
      </div>
      {loading && (
        <div className="w-full my-8">
          <LogoSpinnerFullCentered />
        </div>
      )}
      <div className="text-blue-gray text-sm mb-2 font-regular">
        Want to register instead?{' '}
        <button
          className="text-purple border-0 p-0 underline decoration-solid decoration-1 decoration-purple bg-transparent text-sm cursor-pointer"
          onClick={() => setPageMode('register')}
          type="button"
        >
          Register
        </button>
      </div>
      <div className="text-blue-gray text-sm font-regular">
        Already have an account?{' '}
        <button
          className="text-purple border-0 p-0 underline decoration-solid decoration-1 decoration-purple bg-transparent text-sm cursor-pointer"
          onClick={() => setPageMode('signin')}
          type="button"
        >
          Sign in
        </button>
      </div>
    </form>
  );
};

const envelopeIconStyle = {
  color: '#8B33F7',
  fontSize: '36px',
};

export const ForgotPassword = ({ setPageMode, loading, setLoading }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const onForgot = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const data = await exponentialFetchRetry({
      // reset the password
      fetchHandler: async () => {
        const response = await fetch('/auth/requestpasswordreset', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            emailAddress,
          }),
        });
        return response;
      },
      jsonResponseHandler: async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          setError(responseJson.error);
          setLoading(false);
          return { error: responseJson.error };
        } else {
          setConfirmed(true);
          setError('');
          setLoading(false);
        }
      },
      attributesToLog: { userId: emailAddress, url: 'sign-on' },
    });

    const { error } = data || {};
    if (error) {
      setError(error);
      setLoading(false);
    }
    return { ...data };
  };

  if (isMobile) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        {confirmed ? (
          <>
            <img
              src={mobileForgotPasswordEmailArt}
              alt=""
              className="max-h-[250px] h-[20vh]"
            />{' '}
            <span className="text-[#252C53] text-base phone-h:text-2xl font-montserrat font-bold text-center">
              Password Reset Email
            </span>
            <span className="max-h-[26px] h-[3vh]"></span>
            <span className="text-[#0D95FF] text-xl phone-h:text-3xl font-montserrat font-bold text-center max-w-full w-full break-words">
              {emailAddress}
            </span>
            <span className="max-h-[26px] h-[3vh]"></span>
            <span className="text-[#2C3550] text-base phone-h:text-lg font-montserrat font-medium text-center">
              Please check your email for instructions to reset your password.
              If you don’t receive the email in 5 minutes, check the junk
              folder. For further help contact
            </span>
            <span className="max-h-[26px] h-[3vh]"></span>
            <span className="text-[#0D95FF] text-base phone-h:text-lg font-montserrat font-bold text-center max-w-full w-full break-words">
              support@zync.ai
            </span>
          </>
        ) : (
          <>
            <img
              src={mobileForgotPasswordArt}
              alt=""
              className="max-h-[288px] h-[25vh]"
            />
            <span className="max-h-[40px] h-[3vh]"></span>
            <span className="text-[#303364] text-base phone-h:text-2xl font-roboto font-medium text-center">
              Forgot Password
            </span>
            <span className="max-h-[40px] h-[3vh]"></span>
            <span className="text-[#617182] text-xs phone-h:text-base font-montserrat font-medium text-left">
              Enter your email, and we’ll send a link to reset your password
            </span>
            <input
              className={classNames(
                ' placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full rounded-none px-2',
                error
                  ? 'bg-[#FF00000D] text-[#FF0000] border-[#FF0000]'
                  : 'bg-transparent text-black border-[#3C417B]'
              )}
              placeholder="Email ID"
              value={emailAddress}
              autoFocus
              label="Email"
              type="email"
              onChange={(event) =>
                setEmailAddress(event.target.value?.toLowerCase())
              }
            />
            {error && (
              <span className="text-[#FF0000] font-montserrat font-medium text-xs text-right w-full">
                {error}
              </span>
            )}
            <span className="max-h-[24px] h-[2vh]"></span>
            <button
              className="font-montserrat font-bold text-white bg-[#5C41FF] rounded-2xl text-xl w-full py-3"
              onClick={onForgot}
            >
              Submit
            </button>
            <span className="max-h-[24px] h-[2vh]"></span>
            <div className="flex gap-2 w-full justify-center items-center">
              <span className="bg-[#6C6C6C] h-[0.5px] w-full"></span>
              <span className="w-[119px] font-roboto text-[#888AA4] text-base shrink-0 text-center">
                New to Zync
              </span>
              <span className="bg-[#6C6C6C] h-[0.5px] w-full"></span>
            </div>
            <span className="max-h-[24px] h-[2vh]"></span>
            <button
              className="font-montserrat font-bold text-white bg-[#2E2E2E] rounded-2xl text-xl w-full py-3"
              onClick={() => setPageMode('register')}
              type="button"
            >
              Register
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {!confirmed && (
        <form onSubmit={onForgot}>
          <div className="text-purple font-semibold text-xl pt-8 pb-2 -mt-8">
            Forgot password
          </div>
          <div className="text-blue-gray font-normal text-sm mb-8">
            Enter your email, and we’ll send a link to reset your password.
          </div>
          <TextInput
            autoFocus
            value={emailAddress}
            onChange={(event, value) => setEmailAddress(value)}
            label="Email"
            type="email"
          />
          <p className="text-red font-normal text-sm p-0 mt-0">{error}</p>
          <div className="w-full flex h-12 my-8">
            <ModalButton type="submit" buttonText="Submit" disabled={loading} />
          </div>
          {loading && (
            <div className="w-full my-8">
              <LogoSpinnerFullCentered />
            </div>
          )}
          <button
            className="text-purple border-0 p-0 underline decoration-solid decoration-1 decoration-purple bg-transparent text-sm font-regular cursor-pointer"
            onClick={() => setPageMode('signin')}
            type="button"
          >
            Sign in
          </button>
        </form>
      )}
      {confirmed && (
        <div className="flex flex-col items-center gap-8">
          <FontAwesomeIcon icon={faEnvelope} style={envelopeIconStyle} />
          <p className="text-blue-gray text-sm">
            Please check your email for instructions to reset your password. If
            you don't receive the email in 5 minutes, check the junk folder. For
            further help contact{' '}
            <a href="mailto: support@zync.ai" className="text-blue text-sm">
              support@zync.ai
            </a>
            .
          </p>
          <button
            className="text-purple border-0 p-0 underline decoration-solid decoration-1 decoration-purple bg-transparent text-sm font-regular cursor-pointer self-start"
            onClick={() => setPageMode('signin')}
            type="button"
          >
            Sign in
          </button>
        </div>
      )}
    </>
  );
};

export const ResetPassword = ({
  setPageMode,
  fixedEmailAddress,
  invitationCode,
  loading,
  setLoading,
}) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const onSetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    const data = await exponentialFetchRetry({
      // reset the password
      fetchHandler: async () => {
        const response = await fetch('/auth/performpasswordreset', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            emailAddress: fixedEmailAddress,
            passwordResetCode: invitationCode,
            password,
          }),
        });
        return response;
      },
      jsonResponseHandler: async (response) => {
        const responseJson = await response.json();
        if (responseJson.error) {
          setError(responseJson.error);
          setLoading(false);
          return { error: responseJson.error };
        } else {
          const isLoggedIn = await onLogin(
            fixedEmailAddress,
            password,
            dispatch,
            setError,
            setLoading
          );
          setLoggedIn(isLoggedIn);
          setLoading(false);
        }
      },
      attributesToLog: { userId: fixedEmailAddress, url: 'sign-on' },
    });

    const { error } = data || {};
    if (error) {
      setError(error);
      setLoading(false);
    }
    return { ...data };
  };

  if (loggedIn) {
    return <Redirect to="/portal" />;
  }

  if (isMobile) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <img
          src={mobileForgotPasswordArt}
          alt=""
          className="max-h-[288px] h-[20vh]"
        />
        <span className="text-[#303364] text-base phone-h:text-2xl font-roboto font-medium text-center">
          Set Your Password
        </span>
        <span className="max-h-[40px] h-[3vh]"></span>
        <span className="text-[#617182] text-xs phone-h:text-base font-montserrat font-medium text-left">
          Pick a new password for your Zync Account.
        </span>
        <input
          className=" placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full bg-transparent text-black border-[#3C417B] rounded-none px-2"
          value={fixedEmailAddress}
          label="Email"
          disabled={!!fixedEmailAddress}
          type="email"
        />
        <input
          className=" placeholder-[#C4C4C4]  outline-none border-b h-[8vh] max-h-[63px] w-full bg-transparent text-black border-[#3C417B] rounded-none px-2"
          placeholder="Password"
          value={password}
          label="Password"
          type="password"
          onChange={(event, value) => setPassword(event.target.value)}
        />
        {error && (
          <span className="text-[#FF0000] font-montserrat font-medium text-xs text-right w-full">
            {error}
          </span>
        )}
        <span className="max-h-[24px] h-[2vh]"></span>
        <button
          className="font-montserrat font-bold text-white bg-[#5C41FF] rounded-2xl text-xl w-full py-3"
          onClick={onSetPassword}
        >
          Set Password
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={onSetPassword}>
      <div className="text-purple font-semibold text-xl pt-8 pb-2 -mt-8">
        Set your password
      </div>
      <div className="text-blue-gray font-normal text-sm mb-8">
        Pick a new password for your zync account.
      </div>
      <TextInput
        value={fixedEmailAddress}
        label="Email"
        disabled={!!fixedEmailAddress}
        type="email"
      />
      <TextInput
        value={password}
        autoFocus
        onChange={(event, value) => setPassword(value)}
        label="Password"
        type="password"
        placeholder="Enter password"
      />
      <p className="text-red font-normal text-sm p-0 mt-0">{error}</p>
      <div className="w-full flex h-12 my-8">
        <ModalButton
          type="submit"
          buttonText="Set password"
          disabled={loading}
        />
      </div>
      {loading && (
        <div className="w-full my-8">
          <LogoSpinnerFullCentered />
        </div>
      )}
      <button
        className="text-purple border-0 p-0 underline decoration-solid decoration-1 decoration-purple bg-transparent text-sm font-regular cursor-pointer"
        onClick={() => setPageMode('signin')}
        type="button"
      >
        Sign in
      </button>
    </form>
  );
};

const ResendEmailVerificationEmail = ({ setPageMode }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const emailParam = queryParams.get('email');
  const redirectUrl = queryParams.get('redirectUrl');
  const [isLoading, setIsLoading] = useState(true);
  const [sentEmail, setSentEmail] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!emailParam) {
      setIsLoading(false);
      return;
    }
    const getUser = async () => {
      const user = await fetchUserApi(emailParam);
      const { registrationCode } = user || {};
      if (!registrationCode) {
        setPageMode('signin');
      }
      setIsLoading(false);
    };
    getUser();
  }, [emailParam, setPageMode]);

  const handleOnClick = async () => {
    mixpanel.track('Registration: Resend Email Clicked', {
      distinct_id: emailParam,
    });

    const response = await resendEmailVerificationAPI({
      emailAddress: emailParam,
      redirectUrl: redirectUrl || '/',
    });
    const { error } = response || {};
    if (error) {
      setError('Error sending email, please try again');
      return;
    }
    setSentEmail(true);
  };

  if (isLoading) return <LoadingSpinnerFullCentered />;

  return (
    <div className="flex flex-col gap-2">
      {sentEmail ? (
        <>
          <span className="text-center text-xl text-blue-dark font-semibold">
            Thank you! Check your email to verify your account and finish
            registration.
          </span>
          <span className="text-center text-sm text-blue-dark">
            Tip: Check your Promotions or Spam folder if you don't see it.
          </span>
        </>
      ) : (
        <>
          <span className="text-center text-xl text-blue-dark font-semibold">
            Finish registration to access your workspace.
          </span>
          <div className="flex h-12">
            <ModalButton
              buttonText="Send Email"
              handleOnClick={handleOnClick}
            ></ModalButton>
          </div>
          {error && (
            <p className="text-red font-normal text-sm p-0 mt-0">{error}</p>
          )}
        </>
      )}
    </div>
  );
};

export const SignOn = ({
  pageMode,
  setPageMode,
  closeAuth,
  fixedEmailAddress,
  allowJoinAsGuest,
  invitationCode,
  errorMessage,
  loading,
  setLoading,
  redirectToPortalPageAfterLogIn,
}) => {
  const meetingId = (useSelector((state) => state.meetingState) || {})
    .meetingId;
  switch (pageMode) {
    case 'signup':
      return (
        <SignUp
          meetingId={meetingId}
          closeAuth={closeAuth}
          fixedEmailAddress={fixedEmailAddress}
          errorMessage={errorMessage}
          invitationCode={invitationCode}
          loading={loading}
          setLoading={setLoading}
        />
      );
    case 'signin':
      return (
        <LogIn
          closeAuth={closeAuth}
          setPageMode={setPageMode}
          fixedEmailAddress={fixedEmailAddress}
          allowJoinAsGuest={allowJoinAsGuest}
          errorMessage={errorMessage}
          loading={loading}
          setLoading={setLoading}
          redirectToPortalPageAfterLogIn={redirectToPortalPageAfterLogIn}
        />
      );
    case 'forgotpassword':
      return (
        <ForgotPassword
          closeAuth={closeAuth}
          setPageMode={setPageMode}
          loading={loading}
          setLoading={setLoading}
        />
      );
    case 'resetpassword':
      return (
        <ResetPassword
          closeAuth={closeAuth}
          setPageMode={setPageMode}
          fixedEmailAddress={fixedEmailAddress}
          invitationCode={invitationCode}
          loading={loading}
          setLoading={setLoading}
        />
      );
    case 'joinasguest':
      return (
        <JoinAsGuest
          setPageMode={setPageMode}
          loading={loading}
          setLoading={setLoading}
        />
      );
    case 'register':
      return (
        <Register
          loading={loading}
          setLoading={setLoading}
          allowJoinAsGuest={allowJoinAsGuest}
          setPageMode={setPageMode}
        />
      );
    case 'resend':
      return <ResendEmailVerificationEmail setPageMode={setPageMode} />;
    default:
      return null;
  }
};
