import { useOrganizationPage } from './useOrganizationPage';
import { WorkspaceTable } from './WorkspaceTable';
import {
  LoadingSpinner,
  LoadingSpinnerAbsoluteFullCentered,
} from '../../components/LoadingSpinner';
import { Page404 } from '../page404';
import { Page403 } from '../page403';
import { StripeCustomerPortalButton } from '../../components/StripeCustomerPortalButton';

export const OrganizationPage = () => {
  const {
    userId,
    organization,
    workspaces,
    owner,
    authorized,
    loading,
    handleCreateWorkspace,
    initialError,
    refreshWorkspaces,
  } = useOrganizationPage();

  if (loading) {
    return <LoadingSpinnerAbsoluteFullCentered />;
  }

  if (!organization) {
    return <Page404 />;
  }

  if (!authorized) {
    return <Page403 />;
  }

  return (
    <main className="p-8 max-w-[1080px] mx-auto">
      <div className="md:flex md:items-center md:justify-between mb-8">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl/7 font-bold text-blue-dark sm:truncate sm:text-3xl sm:tracking-tight">
            Organization
          </h2>
        </div>
      </div>

      <div className="pb-5 flex gap-4 items-center">
        {organization.orgLogoUrl ? (
          <img
            src={organization.orgLogoUrl}
            alt=""
            height={50}
            className="max-h-[50px]"
          />
        ) : null}
        <div>
          <h3 className="text-base font-semibold text-gray-900">
            {organization.orgName}
          </h3>
          <a href={`https://${organization.domainName}`}>
            {organization.domainName}
          </a>
        </div>
      </div>

      <div
        className={`flex gap-4 mb-4 items-center ${owner ? '' : 'opacity-0'}`}
      >
        <p className="text-base font-semibold text-gray-900">
          {owner?.hasDefaultPaymentMethod
            ? '✅ Billing Setup Complete'
            : '⚠️ Billing Setup Incomplete'}
        </p>
        <StripeCustomerPortalButton userId={userId} />
      </div>

      {workspaces ? (
        <WorkspaceTable
          workspaces={workspaces}
          handleCreateWorkspace={handleCreateWorkspace}
          initialError={initialError}
          canCreateNewWorkspace={owner?.hasDefaultPaymentMethod}
          refreshWorkspaces={refreshWorkspaces}
        />
      ) : (
        <LoadingSpinner />
      )}
    </main>
  );
};
