import React from 'react';
import classNames from '../../helper/classNames';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import {
  MAX_CHARACTERS,
  MAX_SEGMENTS,
} from '../../blocks/VideoScriptEditorBlock';
import { episodeTypes } from 'zync-common/types';

const BORDER_WIDTH_VARIANTS = {
  NONE: 'NONE',
  THIN: 'THIN',
};

const BORDER_WIDTH_VARIANTS_CLASSNAMES = {
  NONE: 'border-0',
  THIN: 'border',
};

const BORDER_COLOR_VARIANTS = {
  TRANSPARENT: 'TRANSPARENT',
  LIGHT_GRAY: 'LIGHT_GRAY',
};

const BORDER_COLOR_VARIANTS_CLASSNAMES = {
  TRANSPARENT: 'border-transparent',
  LIGHT_GRAY: 'border-[#EBEAED]',
};

const BORDER_RADIUS_VARIANTS = {
  NONE: 'NONE',
  ROUNDED: 'ROUNDED',
};

const BORDER_RADIUS_VARIANTS_CLASSNAMES = {
  NONE: 'rounded-none',
  ROUNDED: 'rounded',
};

export const MeetingVideoScript = React.memo(
  ({
    setVideoScript,
    videoScript,
    borderWidth = BORDER_WIDTH_VARIANTS.NONE,
    borderColor = BORDER_COLOR_VARIANTS.TRANSPARENT,
    borderRadius = BORDER_RADIUS_VARIANTS.NONE,
    disabled = false,
    episodeType,
  }) => {
    const userId = useSelector((st) => st.auth?.user?.userId);

    return (
      <div className="flex flex-grow flex-col gap-2 self-start min-w-0 relative">
        <textarea
          value={videoScript}
          onChange={(event) => {
            const newValue = event.currentTarget.value
              .split('\n\n')
              .slice(0, MAX_SEGMENTS)
              .join('\n\n');
            setVideoScript(newValue);
          }}
          className={classNames(
            'px-9 py-6 font-medium placeholder:text-[#97A0AF] text-blue-gray h-[200px]',
            BORDER_WIDTH_VARIANTS_CLASSNAMES[borderWidth],
            BORDER_COLOR_VARIANTS_CLASSNAMES[borderColor],
            BORDER_RADIUS_VARIANTS_CLASSNAMES[borderRadius]
          )}
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              (videoScript || '').split('\n\n').length >= MAX_SEGMENTS
            ) {
              event.preventDefault();
            }
          }}
          maxLength={MAX_CHARACTERS}
          onBlur={() => {
            mixpanel.track('New Session: Video Script Updated ', {
              distinct_id: userId,
            });
          }}
          disabled={disabled}
        />
        <div
          className={classNames(
            videoScript?.trim().length ? 'invisible' : '',
            'absolute inset-0 text-[#97A0AF] pointer-events-none px-9 py-6'
          )}
        >
          {episodeType === episodeTypes.zyncup ? (
            <>
              <p className="mb-[24px]">
                Write the script for a short intro + question here.
              </p>
              <p>Use a blank line to split your script into segments</p>{' '}
            </>
          ) : (
            <>
              <p className="mb-[24px]">Paste your video script here.</p>
              <p>
                Use a blank line between sentences to automatically split your
                script into segments.
              </p>
            </>
          )}
        </div>
      </div>
    );
  }
);

export const MobileMeetingVideoScript = React.memo(
  ({ setVideoScript, videoScript, disabled = false }) => {
    const userId = useSelector((st) => st.auth?.user?.userId);

    return (
      <div className="flex h-full w-full flex-col gap-2 self-start min-w-0 relative">
        <textarea
          value={videoScript}
          onChange={(event) => {
            const newValue = event.currentTarget.value
              .split('\n\n')
              .slice(0, MAX_SEGMENTS)
              .join('\n\n');
            setVideoScript(newValue);
          }}
          className="h-full w-full p-5 rounded-2xl border-[0.5px] border-[#30336480] placeholder-[#E8E8E8] outline-none font-roboto"
          onKeyDown={(event) => {
            if (
              event.key === 'Enter' &&
              (videoScript || '').split('\n\n').length >= MAX_SEGMENTS
            ) {
              event.preventDefault();
            }
          }}
          maxLength={MAX_CHARACTERS}
          onBlur={() => {
            mixpanel.track('New Session: Video Script Updated ', {
              distinct_id: userId,
            });
          }}
          disabled={disabled}
        />
        <div
          className={classNames(
            videoScript?.trim().length ? 'invisible' : '',
            'absolute inset-0 text-[#97A0AF] pointer-events-none p-5 text-sm'
          )}
        >
          <p className="mb-[24px]">Paste your video script here.</p>
          <p>
            Use a blank line between sentences to automatically split your
            script into segments.
          </p>
        </div>
      </div>
    );
  }
);

MeetingVideoScript.borderWidth = BORDER_WIDTH_VARIANTS;
MeetingVideoScript.borderColor = BORDER_COLOR_VARIANTS;
MeetingVideoScript.borderRadius = BORDER_RADIUS_VARIANTS;
