export const MEETING_TOPIC = {
  heading: 'Topic',
  topics: [
    `Answer Audience Questions`,
    'Respond to key questions from your audience',
  ],
};

export const MEETING_TOPIC_SCRIPT = {
  heading: 'Topic',
  topics: [`Start With Your Script`, 'Set your video topic'],
};

export const ZYNC_UP_MEETING_TOPIC_SCRIPT = {
  heading: 'Topic',
  topics: [`ZyncUp Question`, 'Set your video topic'],
};

export const MEETING_VIDEO_SCRIPT = {
  heading: 'Video Script',
  topics: [
    `Start With Your Script`,
    'Write your script to guide your recording',
  ],
};

export const ZYNC_UP_MEETING_VIDEO_SCRIPT = {
  heading: 'Video Script',
  topics: [
    `Start With Your Script`,
    'Write your question that you plan to record',
  ],
};

export const ADD_HOST = {
  heading: 'Host',
  topics: ['Add host'],
};
export const ADD_SPEAKER = {
  heading: 'Speakers',
  topics: ['Add speakers'],
};
export const SET_DATE = {
  heading: 'Episode Time',
  topics: ['Set Episode Date And Time'],
};
export const SET_DISCUSSION_PTS = {
  heading: 'Discussion Points',
  topics: ['Add Prompts'],
};

export const SET_BLOG_POST = {
  heading: 'Convert Existing Content',
  topics: [
    'Convert Existing Content',
    'Repurpose blogs newsletters or articles',
  ],
};

export const SET_IDEA = {
  heading: 'Your Idea',
  topics: ['Turn Idea to Video', 'Transform Ideas into Video'],
};

export const ZYNC_UP_URL = {
  heading: 'Respond to ZyncUp Session',
  topics: ['Paste the session URL that you want to respond to'],
};

export const ProgressStates = {
  BEFORE: 'BEFORE',
  CURRENT: 'CURRENT',
  AFTER: 'AFTER',
};
export const checkpointNames = {
  MEETING_TOPIC: 'MEETING_TOPIC',
  MEETING_TOPIC_SCRIPT: 'MEETING_TOPIC_SCRIPT',
  MEETING_VIDEO_SCRIPT: 'MEETING_VIDEO_SCRIPT',
  ADD_HOST: 'ADD_HOST',
  ADD_SPEAKER: 'ADD_SPEAKER',
  SET_DATE: 'SET_DATE',
  SET_DISCUSSION_PTS: 'SET_DISCUSSION_PTS',
  SET_BLOG_POST: 'SET_BLOG_POST',
  SET_IDEA: 'SET_IDEA',
  ZYNC_UP_URL: 'ZYNC_UP_URL',
};
export const checkpoints = {
  [checkpointNames.MEETING_TOPIC]: MEETING_TOPIC,
  [checkpointNames.ADD_HOST]: ADD_HOST,
  [checkpointNames.ADD_SPEAKER]: ADD_SPEAKER,
  [checkpointNames.SET_DATE]: SET_DATE,
  //[checkpointNames.SET_DISCUSSION_PTS]: SET_DISCUSSION_PTS,
};

export const soloSessionCreationKeys = {
  idea: 'idea',
  profile: 'profile',
  blogPost: 'blogPost',
  manual: 'manual',
  manualScript: 'manualScript',
  zyncup: 'zyncup',
};
