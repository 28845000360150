import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { usePortal } from '../hooks/usePortal';
import { getHomeUrl, isMobile } from '../helper';
import { LogoSpinnerFullCentered } from '../components/LogoSpinner';
import {
  FuturisticBackground,
  IsometricShapesDecoration,
} from '../components/FuturisticBackground';
import { HomeNavbar } from './Navbar/HomeNavbar';
import { Button } from '../components/common/Button';
import { useSelectDefaultWorkspace } from '../hooks/useSelectDefaultWorkspace';
import { CreateNewEventModal } from './Portal/CreateNewEventModal';
import { isDefaultBrandKit } from 'zync-common/helper/brandKit';
import { DesktopOnlyModal } from '../components/DesktopOnlyModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SettingsIcon } from '../components/icons/SettingsIcon';
import { lightenOrDarkenColor } from '../helper/lightenOrDarkenColor';
import { MovieFilmIcon } from '../components/icons/MovieFilmIcon';
import { Notification, Notifications } from '../components/Notifications';
import { PrivacyPolicyConsentModal } from '../components/PrivacyPolicyConsentModal';
import { episodeTypes } from 'zync-common/types';
import { SoloEpisodeCard } from './Portal/SoloEpisodeCard';
import { checkIsFailedEpisode, checkIsSoloEpisode } from './Portal/shared';
import {
  NewSoloSessionSelectionModal,
  soloSessionCreationOptions,
} from './Portal/NewSoloSessionSelectionModal';
import { logerror } from '../helper/contextualLogger';
import { soloSessionCreationKeys } from './Portal/constants';
import { LoadingSoloEpisodeCard } from './Portal/LoadingSoloEpisodeCard';
import { fetchFeedDataApi } from '../helper/api';
import { WorkspaceContext } from '../App';
import { getStaticAssetUrl } from '../helper/getStaticAssetUrl';
import { WelcomeDirect } from './SoloWelcome';
import { useSelector } from 'react-redux';
import { useWorkspacePlan } from '../hooks/useWorkspacePlan';
import { ModalBody, ModalTitle, ModalWindow } from '../components/Modal';
import { CheckInCircleIcon } from '../components/icons/CheckInCircleIcon';
import classNames from '../helper/classNames';
import { StarIcon } from '../components/icons/StarsIcon';
import { GearIcon } from '../components/icons/GearIcon';
import { isZyncStaff } from '../helper/isZyncStaff';
import { UpgradeButton } from './Navbar/NavbarComponents';
import { MobileSoloPortal } from './MobileSoloPortal/MobileSoloPortal';
import { ArrowUpIcon } from '../components/common/Icon';
import { MediaListIcon } from '../components/icons/MediaListIcon';
import { ArrowOutline } from '../components/icons/ArrowOutline';
import { FailedSessionCreationModal } from '../components/FailedSessionCreationModal';

const AppSumoPlanInfoModal = () => {
  const { workspacePlanLabel } = useWorkspacePlan();

  const [isOpen, setIsOpen] = useState(true);

  return isOpen ? (
    <ModalWindow isOpen={isOpen} onClose={() => setIsOpen(false)} size="sm">
      <ModalTitle>
        <div className="flex gap-2 items-center w-full">
          <CheckInCircleIcon color="#00A3A5" /> Welcome!
        </div>
      </ModalTitle>
      <ModalBody>
        <div className="-mt-4 text-blue-dark mb-4">
          You are about to create a new space with your{' '}
          <strong>{workspacePlanLabel}</strong> plan.
        </div>
        <Button
          onClick={() => setIsOpen(false)}
          color={Button.colors.TEAL}
          padding={Button.padding.MEDIUM}
          size={Button.sizes.FULL}
        >
          <span className="text-sm font-medium">Continue</span>
        </Button>
      </ModalBody>
    </ModalWindow>
  ) : null;
};

const ClipsLeft = () => {
  const { workspace } = useContext(WorkspaceContext);
  const history = useHistory();

  const [isHovered, setIsHovered] = useState(false);

  const { tierLimits, unlimitedClips } = workspace || {};
  const { isActive: isUnlimitedClipsActive = false } = unlimitedClips || {};

  const userId = useSelector((st) => st.auth?.user?.userId);

  if (isUnlimitedClipsActive) {
    return null;
  }

  if (!tierLimits) {
    return null;
  }

  const { limitPerMonth, enforceLimit, clipsCurrentMonth } =
    tierLimits?.clips || {};

  if (!enforceLimit) {
    return null;
  }

  const clipsLeft = limitPerMonth - Math.min(clipsCurrentMonth, limitPerMonth);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative"
    >
      <div className="text-white font-bold font-roboto">
        {clipsLeft} OF {limitPerMonth} VIDEOS LEFT
      </div>

      {isHovered ? (
        <div className="absolute bg-white p-3 bottom-0 right-0 translate-y-full z-10 flex items-center shadow-xl rounded-md border border-1 border-gray/50 gap-3">
          <p className="w-[364px] font-sm text-blue-dark font-medium">
            Upgrade for Unlimited Clips
          </p>
          <Button
            color={Button.colors.ORANGE}
            padding={Button.padding.SEMI_SMALL}
            onClick={() => {
              mixpanel.track('Portal: Clicked Upgrade', {
                distinct_id: userId,
              });
              history.push(
                `/workspace/billing?workspaceId=${workspace.workspaceId}`
              );
            }}
          >
            <div className="flex gap-1.5 items-center">
              <img
                width={24}
                height={24}
                src={getStaticAssetUrl('chargingIcon.png')}
                alt=""
              />
              <span className="text-sm">Upgrade</span>
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const Container = React.memo(({ children }) => (
  <div className="flex flex-col overflow-hidden gap-6 max-w-[1440px] py-10 px-[55px] mx-auto">
    {children}
  </div>
));

export const SoloPortal = () => {
  const {
    //authMode,
    authenticated,
    redirectToLanding,
    seriesList,
    seriesListLoading,
    brandKit,
    workspaceId,
    workspacePlan,
    currentWorkspaceName,
    isTrial,
    plan,
    soloSettings,
    workspacePlanValidUntil,
    currentSubscriptionRenewalTime,
    showPrivacyPolicyModal,
    setShowPrivacyPolicyModal,
    creatorUserId,
    fetchNewMeetings,
    requiresOnboarding,
    featureFlags,
    showFailedSessionCreationModal,
    setShowFailedSessionCreationModal,
  } = usePortal();

  const { workspaceType } = useWorkspacePlan();

  const [
    isNewManualScriptSoloSessionModalOpen,
    setIsNewManualScriptSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewBlogPostBasedSoloSessionModalOpen,
    setIsNewBlogPostBasedSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewIdeaBasedSoloSessionModalOpen,
    setIsNewIdeaBasedSoloSessionModalOpen,
  ] = useState(false);

  const [
    isNewProfileBasedSoloSessionModalOpen,
    setIsNewProfileBasedSoloSessionModalOpen,
  ] = useState(false);

  const [isNewZyncUpSessionModalOpen, setIsNewZyncUpSessionModalOpen] =
    useState(false);

  const [isNewRSessionModalOpen, setIsNewRSessionModalOpen] = useState(false);

  const [
    isNewSoloSessionSelectionModalOpen,
    setIsNewSoloSessionSelectionModalOpen,
  ] = useState(false);

  const [showDesktopOnlyModal, setShowDesktopOnlyModal] = useState(false);

  const [isFeedDataLoading, setIsFeedDataLoading] = useState(true);
  const [feedData, setFeedData] = useState(null);

  const getDesktopOnlyModalMessage = () => {
    switch (showDesktopOnlyModal) {
      case 'eventPage': {
        return 'The event page is only accessible from a desktop device.';
      }
      case 'eventCreation':
      default: {
        return 'New event creation can only happen from a desktop device.';
      }
    }
  };

  useSelectDefaultWorkspace(workspaceId);

  useEffect(() => {
    document.title = `${currentWorkspaceName || 'Studio'}`;
    return () => {
      document.title = `Studio`;
    };
  }, [currentWorkspaceName]);

  const userId = useSelector((st) => st.auth?.user?.userId);

  const { isAppSumo } = useWorkspacePlan();

  useEffect(() => {
    mixpanel.track('Portal: Entered Solo Portal', { distinct_id: userId });
  }, [userId]);

  useEffect(() => {
    if (!workspaceId) return;
    const fetchFeedData = async () => {
      const result = await fetchFeedDataApi(workspaceId);
      if (!result?.feedPage) {
        setIsFeedDataLoading(false);
        return;
      }

      setIsFeedDataLoading(false);
      setFeedData(result?.feedPage);
    };
    fetchFeedData();
  }, [workspaceId]);

  const filteredSeriesList = (seriesList || []).reduce(
    (filteredList, episode) => {
      switch (true) {
        case checkIsFailedEpisode(episode): {
          // do nothing
          break;
        }
        case checkIsSoloEpisode(episode): {
          filteredList.soloEpisodes.push(episode);
          break;
        }
        default: {
          break;
        }
      }

      return filteredList;
    },
    {
      soloEpisodes: [],
    }
  );

  const { soloEpisodes } = filteredSeriesList;

  const defaultSpeaker = soloSettings?.defaultSpeaker;
  const hasDefaultSpeaker = Boolean(defaultSpeaker);

  if (requiresOnboarding) {
    return (
      <>
        {isAppSumo ? <AppSumoPlanInfoModal /> : null}
        <WelcomeDirect workspaceId={workspaceId} />
      </>
    );
  }

  if (workspaceType === 'podcast') {
    return <Redirect to="/" />;
  }

  if (redirectToLanding) {
    return <Redirect to={getHomeUrl()} />;
  }

  if (isMobile) {
    return (
      <MobileSoloPortal
        seriesListLoading={seriesListLoading}
        brandKit={brandKit}
        soloSettings={soloSettings}
        currentWorkspaceName={currentWorkspaceName}
        workspaceId={workspaceId}
        soloEpisodes={soloEpisodes}
        plan={plan}
        fetchNewMeetings={fetchNewMeetings}
        workspacePlan={workspacePlan}
        creatorUserId={creatorUserId}
        featureFlags={featureFlags}
      />
    );
  }

  return (
    <>
      {showFailedSessionCreationModal && (
        <FailedSessionCreationModal
          onClose={() => {
            setShowFailedSessionCreationModal(false);
          }}
        />
      )}

      {showPrivacyPolicyModal && (
        <PrivacyPolicyConsentModal
          setShowPrivacyPolicyModal={setShowPrivacyPolicyModal}
        />
      )}
      {showDesktopOnlyModal && (
        <DesktopOnlyModal
          message={getDesktopOnlyModalMessage()}
          onClose={() => setShowDesktopOnlyModal(false)}
        />
      )}
      <FuturisticBackground
        showDecorations={false}
        showGradient={false}
        showIsometricLinesDecoration={false}
        topGradientOpacity={FuturisticBackground.topGradientOpacity.LOW}
      />
      <HomeNavbar
        isAuthenticated={authenticated}
        workspaceId={workspaceId}
        isTrial={isTrial}
        plan={plan}
        trialUntil={workspacePlanValidUntil}
        workspacePlanValidUntil={workspacePlanValidUntil}
        currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
        showCreateRSession={true}
        setIsNewRSessionModalOpen={setIsNewRSessionModalOpen}
      />
      {seriesListLoading && !brandKit?.primaryColor ? (
        <div className="h-[90vh] grid place-content-center">
          <LogoSpinnerFullCentered />
        </div>
      ) : (
        <div>
          <div className="relative">
            <Container>
              {defaultSpeaker && (
                <div
                  className="relative isolate min-h-[250px] flex flex-col justify-center rounded-[25px] overflow-hidden pb-12 shadow-800"
                  style={{
                    backgroundImage: `linear-gradient(${lightenOrDarkenColor(
                      brandKit?.primaryColor,
                      20
                    )} 1px, transparent 1px), linear-gradient(to right, ${lightenOrDarkenColor(
                      brandKit?.primaryColor,
                      20
                    )} 1px, ${brandKit.primaryColor} 1px)`,
                    backgroundSize: '40px 40px',
                    backgroundPositionY: '20px',
                  }}
                >
                  {isZyncStaff(userId) ? (
                    <div className="absolute right-10 top-4 z-10">
                      <Link to={`/admin/?workspaceId=${workspaceId}`}>
                        <span className="sr-only">
                          Click to open workspace admin page
                        </span>
                        <GearIcon
                          className="fill-black"
                          style={{
                            fill: brandKit.accentContrast,
                          }}
                        />
                      </Link>
                    </div>
                  ) : null}
                  <div className="absolute right-4 top-4 z-10">
                    <Link
                      to={`/workspace/${encodeURIComponent(
                        'solo studio settings'
                      )}?workspaceId=${workspaceId}`}
                    >
                      <span className="sr-only">
                        Click to open solo studio settings
                      </span>
                      <SettingsIcon
                        width="17px"
                        height="16px"
                        className="fill-white"
                        style={{
                          fill: brandKit.accentContrast,
                        }}
                      />
                    </Link>
                  </div>
                  <h1
                    className="font-jakarta font-bold z-10 text-[28px] md:text-[48px] leading-[125%] md:max-w-[70%] md:ml-14 text-center md:text-left line-clamp-2 mb-1"
                    style={{
                      color: brandKit.primaryContrast,
                    }}
                  >
                    {defaultSpeaker.fullName}
                  </h1>
                  {currentWorkspaceName && (
                    <h2
                      className="font-inter text-2xl font-bold md:ml-14 md:max-w-[80%] text-center md:text-left"
                      style={{
                        color: brandKit.primaryContrast,
                      }}
                    >
                      {currentWorkspaceName}
                    </h2>
                  )}
                  <div
                    className="absolute h-[75%] aspect-square rounded-full -right-10 top-[-10%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  <div
                    className="absolute h-[30%] aspect-square rounded-full right-28 top-[60%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  <div
                    className="absolute h-[60%] aspect-square rounded-full -right-8 top-[75%]"
                    style={{
                      backgroundColor: brandKit.accentColor,
                    }}
                  />
                  {!isFeedDataLoading &&
                    (feedData?.highlights || []).length > 0 &&
                    (isMobile ? (
                      <div className="h-[40px] absolute self-center md:self-start bottom-4 flex mx-auto md:ml-20 md:mr-0 bg-white text-[#696F8C] font-medium w-fit rounded-full border border-[#D0D5DD]">
                        <Link
                          to={`/${workspaceId}/videofeed`}
                          onClick={() => {
                            mixpanel.track('Portal: Clicked Video Reel', {
                              distinct_id: userId,
                            });
                          }}
                          className="flex gap-2 items-center px-2 py-1 whitespace-nowrap text-sm"
                        >
                          <MovieFilmIcon
                            width="30px"
                            height="30px"
                            color="#696F8C"
                          />
                          Video Reel
                        </Link>
                      </div>
                    ) : (
                      <div className="absolute self-center md:self-start bottom-8 flex mx-auto md:ml-14 md:mr-0 bg-white text-[#696F8C] font-medium w-fit rounded-full border border-[#D0D5DD]">
                        <Link
                          to={`/${workspaceId}/videofeed`}
                          onClick={() => {
                            mixpanel.track('Portal: Clicked Video Reel', {
                              distinct_id: userId,
                            });
                          }}
                          className="flex gap-2 items-center px-2 py-1"
                        >
                          <MovieFilmIcon
                            width="20px"
                            height="20px"
                            color="#696F8C"
                          />
                          Video Reel
                        </Link>
                      </div>
                    ))}
                </div>
              )}
              <Notifications
                render={[
                  {
                    component: (
                      <Notification
                        key={1}
                        title={
                          <Link to="/workspace/settings">
                            SETUP YOUR BRAND KIT
                          </Link>
                        }
                        message={`You will have to setup your brand kit before running your first episode.${
                          isDefaultBrandKit(brandKit)
                            ? ' You are now using default Zync brand kit settings'
                            : ''
                        }`}
                      />
                    ),
                    when: isDefaultBrandKit(brandKit),
                  },
                ]}
              />
              {seriesListLoading ? (
                <LogoSpinnerFullCentered />
              ) : (
                <>
                  <div className="px-12 w-full flex flex-col gap-5 lg:items-start items-center max-w-full self-center mt-[26px]">
                    <span className="text-blue-dark font-semibold text-xl">
                      Start Creating Your Next Video
                    </span>
                    <div className="flex lg:flex-row flex-col flex-wrap gap-6 lg:gap-10 lg:max-w-full max-w-[368px]">
                      {soloSessionCreationOptions.map(
                        ({
                          aiSuggest,
                          itemKey,
                          icon,
                          header,
                          description,
                          linkText,
                          link,
                        }) => {
                          return (
                            <button
                              key={`option-${itemKey}`}
                              style={{
                                flex: `${soloSessionCreationOptions.length} 0 200px`,
                                minWidth: 0,
                                boxShadow:
                                  '0px 4px 4px -2px rgba(24, 39, 75, 0.08), 0px 2px 4px -2px rgba(24, 39, 75, 0.12)',
                              }}
                              className={classNames(
                                'group relative md:min-w-[220px] max-w-[270px] lg:w-[270px] bg-[#FCFAFF] rounded-[10px] flex justify-between items-center min-h-[104px]',
                                !hasDefaultSpeaker
                                  ? 'pointer-events-none opacity-50'
                                  : ''
                              )}
                              onClick={() => {
                                if (
                                  plan === 'free' &&
                                  itemKey !==
                                    soloSessionCreationKeys.manualScript &&
                                  itemKey !== soloSessionCreationKeys.zyncup
                                ) {
                                  return;
                                }
                                switch (itemKey) {
                                  case soloSessionCreationKeys.idea: {
                                    mixpanel.track(
                                      'Portal: New Session - From Idea',
                                      {
                                        distinct_id: userId,
                                      }
                                    );
                                    setIsNewIdeaBasedSoloSessionModalOpen(true);
                                    break;
                                  }
                                  case soloSessionCreationKeys.profile: {
                                    setIsNewProfileBasedSoloSessionModalOpen(
                                      true
                                    );
                                    break;
                                  }
                                  case soloSessionCreationKeys.blogPost: {
                                    mixpanel.track(
                                      'Portal: New Session - From Content',
                                      {
                                        distinct_id: userId,
                                      }
                                    );
                                    setIsNewBlogPostBasedSoloSessionModalOpen(
                                      true
                                    );
                                    break;
                                  }
                                  case soloSessionCreationKeys.manualScript: {
                                    mixpanel.track(
                                      'Portal: New Session - From Script',
                                      {
                                        distinct_id: userId,
                                      }
                                    );
                                    setIsNewManualScriptSoloSessionModalOpen(
                                      true
                                    );
                                    break;
                                  }
                                  case soloSessionCreationKeys.zyncup: {
                                    mixpanel.track(
                                      'Portal: New Session - Zync Up',
                                      { distinct_id: userId }
                                    );
                                    setIsNewZyncUpSessionModalOpen(true);
                                    break;
                                  }
                                  default: {
                                    logerror({
                                      message: `soloSessionCreationKey ${itemKey} not found. Did you add a new solo session creation key but did not update the onConfirm handler?`,
                                    });
                                  }
                                }
                              }}
                            >
                              {plan === 'free' &&
                              itemKey !==
                                soloSessionCreationKeys.manualScript &&
                              itemKey !== soloSessionCreationKeys.zyncup ? (
                                <div className="hidden cursor-default group-hover:grid absolute inset-0 bg-white place-content-center rounded-lg z-10">
                                  <UpgradeButton />
                                </div>
                              ) : null}

                              <div className="flex gap-4 items-stretch h-full">
                                <div
                                  className={`grid place-content-center w-[32%] py-6 rounded-l-lg ${
                                    itemKey === soloSessionCreationKeys.zyncup
                                      ? 'bg-[#ff0000]/30'
                                      : 'bg-purple/50'
                                  } h-full shrink-0`}
                                >
                                  <div className="flex flex-col items-center gap-1">
                                    <div className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] bg-white rounded-full flex justify-center items-center">
                                      {icon}
                                    </div>
                                    <div className="rotate-180">
                                      <ArrowUpIcon
                                        width={16}
                                        height={16}
                                        color="white"
                                      />
                                    </div>
                                    <div
                                      className={`min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] ${
                                        itemKey ===
                                        soloSessionCreationKeys.zyncup
                                          ? 'bg-[#ff0000]'
                                          : 'bg-purple'
                                      }  rounded-full flex justify-center items-center`}
                                    >
                                      <MediaListIcon />
                                    </div>
                                  </div>
                                </div>
                                <div className="min-h-[64px] flex flex-col text-left justify-center relative pr-1">
                                  <span className="text-[12px] text-black font-medium">
                                    {header}
                                  </span>
                                  <span className="text-xs text-blue-gray">
                                    {description}
                                  </span>
                                  {link && linkText && (
                                    <div
                                      className="absolute bottom-3 mt-3 flex gap-1.5 items-center text-purple text-xxs self-start decoration-blue-light underline whitespace-nowrap"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(
                                          link,
                                          '_blank',
                                          'noopener,noreferrer'
                                        );
                                      }}
                                    >
                                      {linkText} <ArrowOutline />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  'ml-1 flex gap-[5px] items-center text-black font-medium text-[9px] whitespace-nowrap absolute right-6 top-2',
                                  aiSuggest ? '' : 'hidden'
                                )}
                              >
                                <StarIcon /> Ai Suggest
                              </div>
                              <div
                                className={classNames(
                                  'ml-1 flex gap-[5px] items-center text-black font-medium text-[9px] whitespace-nowrap absolute right-6 top-2',
                                  itemKey === soloSessionCreationKeys.zyncup
                                    ? ''
                                    : 'hidden'
                                )}
                              >
                                <span className="text-base">🎉</span> New
                              </div>
                            </button>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="mb-4 flex flex-col items-center 2xl:px-0 px-12 gap-0.5">
                    <div className="bg-purple rounded-[10px] px-[42px] py-5 flex justify-between items-center w-full max-w-[1225px]">
                      <h2 className="text-xl font-medium font-roboto text-white">
                        Video Recording Sessions
                      </h2>
                      <ClipsLeft />
                    </div>
                    <div className="flex flex-col w-full max-w-[1225px] gap-0.5">
                      {soloEpisodes.length ? (
                        soloEpisodes.map((series, i) =>
                          series.settings.episodeType ===
                          episodeTypes.placeholder ? (
                            <LoadingSoloEpisodeCard
                              key={series.meetingSeriesId}
                            />
                          ) : (
                            <SoloEpisodeCard
                              key={series.meetingSeriesId}
                              series={series}
                              setShowDesktopOnlyModal={setShowDesktopOnlyModal}
                              featureFlags={featureFlags}
                            />
                          )
                        )
                      ) : (
                        <div className="flex gap-4 justify-center items-center border border-t-0 border-gray/50 text-blue-gray text-center font-medium text-sm rounded-bl-lg rounded-br-lg p-4">
                          No recording sessions yet. Start your first video now!
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Container>
          </div>
        </div>
      )}
      {isNewSoloSessionSelectionModalOpen && (
        <NewSoloSessionSelectionModal
          disabled={!hasDefaultSpeaker}
          onClose={() => setIsNewSoloSessionSelectionModalOpen(false)}
          onConfirm={(soloSessionCreationKey) => {
            switch (soloSessionCreationKey) {
              case soloSessionCreationKeys.idea: {
                mixpanel.track('Portal: New Session - From Idea', {
                  distinct_id: userId,
                });
                setIsNewIdeaBasedSoloSessionModalOpen(true);
                break;
              }
              case soloSessionCreationKeys.profile: {
                setIsNewProfileBasedSoloSessionModalOpen(true);
                break;
              }
              case soloSessionCreationKeys.blogPost: {
                mixpanel.track('Portal: New Session - From Content', {
                  distinct_id: userId,
                });
                setIsNewBlogPostBasedSoloSessionModalOpen(true);
                break;
              }
              case soloSessionCreationKeys.manualScript: {
                mixpanel.track('Portal: New Session - From Script', {
                  distinct_id: userId,
                });
                setIsNewManualScriptSoloSessionModalOpen(true);
                break;
              }
              default: {
                logerror({
                  message: `soloSessionCreationKey ${soloSessionCreationKey} not found. Did you add a new solo session creation key but did not update the onConfirm handler?`,
                });
              }
            }
          }}
        />
      )}
      {isNewManualScriptSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewManualScriptSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloScriptBased}
        />
      )}
      {isNewBlogPostBasedSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewBlogPostBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloBlogBased}
        />
      )}
      {isNewProfileBasedSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewProfileBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloProfileBased}
        />
      )}
      {isNewIdeaBasedSoloSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewIdeaBasedSoloSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.soloIdeaBased}
        />
      )}
      {isNewZyncUpSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewZyncUpSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.zyncup}
        />
      )}
      {isNewRSessionModalOpen && (
        <CreateNewEventModal
          onClose={() => {
            setIsNewRSessionModalOpen(false);
            fetchNewMeetings();
          }}
          workspaceId={workspaceId}
          workspacePlan={workspacePlan}
          defaultHostId={creatorUserId}
          usePreview={false}
          episodeType={episodeTypes.responder}
        />
      )}
      <IsometricShapesDecoration className="absolute left-0 w-full object-cover" />
    </>
  );
};
