export const MediaListIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4.3V10.7C10.5 10.8657 10.3657 11 10.2 11H3.8C3.63431 11 3.5 10.8657 3.5 10.7V4.3C3.5 4.13431 3.63431 4 3.8 4H10.2C10.3657 4 10.5 4.13431 10.5 4.3Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 2.5H2.3C2.13431 2.5 2 2.63431 2 2.8V9.5"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.45435 6.2727C6.2544 6.15275 6 6.29675 6 6.52995V8.47025C6 8.70345 6.2544 8.84745 6.45435 8.7275L8.07125 7.75735C8.26545 7.64085 8.26545 7.35935 8.07125 7.24285L6.45435 6.2727Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
