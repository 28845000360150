import React from 'react';
import classNames from '../../helper/classNames';
import { LoadingSpinner } from '../LoadingSpinner';

const BUTTON_STATES = {
  DEFAULT: 'DEFAULT',
  LOADING: 'LOADING',
  CONFIRM: 'CONFIRM',
  DISABLED: 'DISABLED',
};

/* Map of all available button variants */
const BUTTON_COLORS_VARIANTS = {
  PURPLE: 'PURPLE', // primary
  PURPLE_LITE: 'PURPLE_LITE',
  BLUE: 'BLUE', // secondary
  BLUE_LIGHTER: 'BLUE_LIGHTER',
  ORANGE: 'ORANGE',
  WHITE: 'WHITE',
  WHITE_LITE: 'WHITE_LITE',
  GRAY: 'GRAY',
  RED: 'RED',
  PINK: 'PINK',
  GRADIENT_ORANGE_TO_PURPLE: 'GRADIENT_ORANGE_TO_PURPLE',
  TEAL: 'TEAL',
  PURPLE_OPACITY_5: 'PURPLE_OPACITY_5',
  TOMATO: 'TOMATO',
  TRANSPARENT: 'TRANSPARENT',
};

const BACKGROUND_VARIANTS = {
  STANDARD: 'STANDARD',
  TRANSPARENT: 'TRANSPARENT',
  NONE: 'NONE',
};

const BUTTON_SIZE_VARIANTS = {
  FULL: 'FULL',
  FIT: 'FIT',
};

const BUTTON_PADDING_VARIANTS = {
  NONE: 'NONE',
  SMALL: 'SMALL',
  SEMI_SMALL: 'SEMI_SMALL',
  MEDIUM: 'MEDIUM',
  SEMI_MEDIUM: 'SEMI_MEDIUM',
  LARGE: 'LARGE',
  EVEN_XS: 'EVEN_XS',
};

const BUTTON_BORDER_VARIANTS = {
  STANDARD: 'STANDARD',
  ROUNDED: 'ROUNDED',
  ROUNDED_GLASS: 'ROUNDED_GLASS',
  ROUNDED_MD: 'ROUNDED_MD',
  ROUNDED_LG: 'ROUNDED_LG',
};

/* Default Tailwind classes for all button variants */
const DEFAULT_CLASSES =
  'relative cursor-pointer text-xl text-center border-solid border-opacity-20 border hover:shadow-md flex justify-center items-center gap-2 transition-transform';

/* Specific Tailwind classes for individual button variant */
const COLOR_VARIANTS_CLASSNAMES = {
  PURPLE: 'bg-purple border-purple text-white',
  PURPLE_LITE: 'bg-white border-purple text-purple border-opacity-100',
  ORANGE: 'bg-orange-light border-orange-light text-white',
  BLUE: 'bg-lavender border-blue text-white',
  BLUE_LIGHTER: 'bg-blue-lighter border blue text-white',
  WHITE: 'bg-white border-light-gray text-blue-gray',
  WHITE_LITE: 'bg-white border-white text-blue-gray',
  GRAY: 'bg-gray text-blue-gray border-light-gray',
  RED: 'bg-red border-red text-white',
  PINK: 'bg-[#f8155c] border-[#f8155c] text-white',
  GRADIENT_ORANGE_TO_PURPLE:
    'bg-gradient-to-r from-[#FF5315] to-purple border-none',
  TEAL: 'bg-[#00a3a5] border-[#00a3a5] text-white',
  PURPLE_OPACITY_5:
    'bg-purple/5 border-[#EBEAED] text-[#97A0AF] border-opacity-100',
  TOMATO: 'bg-[#FE5F55] text-white',
  TRANSPARENT: 'bg-transparent',
};

const BACKGROUND_VARIANTS_CLASSNAMES = {
  STANDARD: 'bg-opacity-[100%]',
  TRANSPARENT: 'bg-opacity-[75%]',
  NONE: 'bg-opacity-0',
};

const SIZE_VARIANTS_CLASSNAMES = {
  FULL: 'w-full',
  FIT: 'w-fit',
};

const PADDING_VARIANTS_CLASSNAMES = {
  NONE: 'p-0',
  SMALL: 'px-3 py-1',
  SEMI_SMALL: 'px-3 py-2',
  MEDIUM: 'px-5 py-3',
  SEMI_MEDIUM: 'px-6 py-4',
  LARGE: 'px-7 py-5',
  EVEN_XS: 'p-1',
};

const BORDER_RADIUS_VARIANTS_CLASSNAMES = {
  STANDARD: 'rounded active:translate-y-0.5',
  ROUNDED: 'rounded-full active:translate-y-0.5',
  ROUNDED_GLASS: 'rounded-full glassy-border border-rounded-full',
  ROUNDED_MD: 'rounded-md',
  ROUNDED_LG: 'rounded-lg',
};

const STATES_CLASSNAMES = {
  LOADING: 'pointer-events-none',
};

const getVisibility = (shouldBeVisible) => {
  return shouldBeVisible ? 'visible' : 'invisible';
};

/** Only intended to be added when disabled, not loading. */
const disabledClassNames = 'disabled:opacity-50 disabled:cursor-default';

/* A standardized button (in future it will replace ActionButton) */
export const Button = React.forwardRef(
  (
    {
      children,
      type = 'button',
      onClick,
      color = BUTTON_COLORS_VARIANTS.WHITE,
      background = BACKGROUND_VARIANTS_CLASSNAMES.STANDARD,
      state = BUTTON_STATES.DEFAULT,
      padding = BUTTON_PADDING_VARIANTS.MEDIUM,
      border = BUTTON_BORDER_VARIANTS.STANDARD,
      size,
      onClickCapture,
      confirmLabel,
    },
    ref
  ) => {
    const isLoading = state === BUTTON_STATES.LOADING;
    const isDisabled = state === BUTTON_STATES.DISABLED;
    return (
      <button
        type={type}
        onClick={onClick}
        onClickCapture={onClickCapture}
        className={classNames(
          DEFAULT_CLASSES,
          COLOR_VARIANTS_CLASSNAMES[color],
          BACKGROUND_VARIANTS_CLASSNAMES[background],
          SIZE_VARIANTS_CLASSNAMES[size],
          PADDING_VARIANTS_CLASSNAMES[padding],
          BORDER_RADIUS_VARIANTS_CLASSNAMES[border],
          STATES_CLASSNAMES[state],
          getVisibility(!isLoading),
          isDisabled && disabledClassNames,
          padding === BUTTON_PADDING_VARIANTS.NONE ? 'border-0' : '',
          state === BUTTON_STATES.DISABLED ? 'pointer-events-none' : ''
        )}
        ref={ref}
        disabled={isDisabled || isLoading}
      >
        {state === BUTTON_STATES.CONFIRM && (
          <div className="absolute inset-0">{confirmLabel}</div>
        )}
        <div className={classNames('absolute', getVisibility(isLoading))}>
          <LoadingSpinner width="36px" />
        </div>
        {children}
      </button>
    );
  }
);

Button.colors = BUTTON_COLORS_VARIANTS;
Button.background = BACKGROUND_VARIANTS;
Button.states = BUTTON_STATES;
Button.sizes = BUTTON_SIZE_VARIANTS;
Button.padding = BUTTON_PADDING_VARIANTS;
Button.border = BUTTON_BORDER_VARIANTS;
