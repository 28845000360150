export const LightBulb = ({ width = 12, height = 13, color = '#8B33F7' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3740_1410)">
        <path
          d="M6.00054 0.875013C5.50773 0.873707 5.01953 0.969791 4.56397 1.15775C4.10825 1.34577 3.69419 1.62201 3.3456 1.9706C2.99701 2.31919 2.72077 2.73325 2.53275 3.18897C2.34479 3.64453 2.24871 4.13273 2.25001 4.62554C2.25001 6.11658 3.0159 7.11576 3.39885 7.42896C3.43317 7.46136 3.46808 7.49407 3.50335 7.52712C3.724 7.73388 3.95893 7.95402 4.15285 8.19394C4.38157 8.4769 4.50001 8.72504 4.50001 8.93751V9.50001C4.50001 9.70712 4.66791 9.87501 4.87501 9.87501C5.08212 9.87501 5.25001 9.70712 5.25001 9.50001V8.93751C5.25001 8.46327 4.99756 8.0459 4.73614 7.72248C4.50662 7.43852 4.22913 7.17893 4.01083 6.97472L4.01041 6.97432C3.97661 6.94271 3.94423 6.91241 3.91368 6.88357C3.33651 6.33875 3.00001 5.51471 3.00001 4.62501L3.00001 4.62397C2.99891 4.22983 3.07573 3.83936 3.22605 3.47502C3.37638 3.11067 3.59724 2.77963 3.87593 2.50093C4.15463 2.22224 4.48567 2.00138 4.85002 1.85105C5.21436 1.70073 5.60483 1.62391 5.99897 1.62501L6.00001 1.62501C7.67378 1.62501 9.00001 2.95666 9.00001 4.62501C9.00001 5.54882 8.68014 6.31591 8.08463 6.8852L8.08456 6.88527C8.0501 6.91823 8.01333 6.953 7.97484 6.98941C7.75837 7.19415 7.48713 7.45069 7.26284 7.72838C7.00241 8.05081 6.75001 8.46627 6.75001 8.93751V9.50001C6.75001 9.70712 6.91791 9.87501 7.12501 9.87501C7.33212 9.87501 7.50001 9.70712 7.50001 9.50001V8.93751C7.50001 8.72907 7.61722 8.48324 7.84629 8.19964C8.03606 7.96469 8.26466 7.74812 8.48221 7.54202L8.48256 7.54169L8.48333 7.54096C8.52368 7.50274 8.56363 7.46488 8.60289 7.42733C9.35926 6.70427 9.75001 5.73511 9.75001 4.62501C9.75001 2.54354 8.08919 0.875293 6.00054 0.875013Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.875 11.75C4.875 11.5429 5.04289 11.375 5.25 11.375H6.75C6.95711 11.375 7.125 11.5429 7.125 11.75C7.125 11.9571 6.95711 12.125 6.75 12.125H5.25C5.04289 12.125 4.875 11.9571 4.875 11.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 10.625C4.5 10.4179 4.66789 10.25 4.875 10.25H7.125C7.33211 10.25 7.5 10.4179 7.5 10.625C7.5 10.8321 7.33211 11 7.125 11H4.875C4.66789 11 4.5 10.8321 4.5 10.625Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6.125C6.20711 6.125 6.375 6.29289 6.375 6.5V9.5C6.375 9.70711 6.20711 9.875 6 9.875C5.79289 9.875 5.625 9.70711 5.625 9.5V6.5C5.625 6.29289 5.79289 6.125 6 6.125Z"
          fill={color}
        />
        <path
          d="M6.00004 5.97266C5.53085 5.94422 5.40666 5.86835 5.31616 5.81306C5.25747 5.7772 5.21294 5.75 5.0977 5.75C4.93363 5.75 4.82322 5.87585 4.78129 5.94922C4.68754 6.11328 4.74613 6.32924 4.88676 6.42969C5.29692 6.72266 5.70707 6.875 6.00004 6.875C6.29301 6.875 6.70317 6.72266 7.11333 6.42969C7.25395 6.32924 7.31255 6.11328 7.2188 5.94922C7.17687 5.87585 7.06645 5.75 6.90239 5.75C6.78714 5.75 6.74262 5.7772 6.68393 5.81306C6.59343 5.86835 6.46923 5.94422 6.00004 5.97266Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3740_1410">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
