export const ArrowOutline = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3740_1721)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.25 1.5C11.25 1.30109 11.171 1.11032 11.0303 0.96967C10.8897 0.829018 10.6989 0.75 10.5 0.75H1.5C1.30109 0.75 1.11032 0.829018 0.96967 0.96967C0.829018 1.11032 0.75 1.30109 0.75 1.5V10.5C0.75 10.6989 0.829018 10.8897 0.96967 11.0303C1.11032 11.171 1.30109 11.25 1.5 11.25H10.5C10.6989 11.25 10.8897 11.171 11.0303 11.0303C11.171 10.8897 11.25 10.6989 11.25 10.5V1.5ZM0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0L10.5 0C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V1.5ZM3.375 5.625C3.27554 5.625 3.18016 5.66451 3.10984 5.73484C3.03951 5.80516 3 5.90054 3 6C3 6.09946 3.03951 6.19484 3.10984 6.26517C3.18016 6.33549 3.27554 6.375 3.375 6.375H7.71975L6.1095 7.9845C6.07463 8.01937 6.04698 8.06076 6.02811 8.10631C6.00924 8.15187 5.99953 8.20069 5.99953 8.25C5.99953 8.29931 6.00924 8.34813 6.02811 8.39369C6.04698 8.43924 6.07463 8.48063 6.1095 8.5155C6.14437 8.55037 6.18576 8.57802 6.23131 8.59689C6.27687 8.61576 6.32569 8.62547 6.375 8.62547C6.42431 8.62547 6.47313 8.61576 6.51869 8.59689C6.56424 8.57802 6.60563 8.55037 6.6405 8.5155L8.8905 6.2655C8.92542 6.23067 8.95313 6.18928 8.97203 6.14372C8.99094 6.09817 9.00067 6.04933 9.00067 6C9.00067 5.95067 8.99094 5.90183 8.97203 5.85627C8.95313 5.81072 8.92542 5.76933 8.8905 5.7345L6.6405 3.4845C6.60563 3.44963 6.56424 3.42198 6.51869 3.40311C6.47313 3.38424 6.42431 3.37453 6.375 3.37453C6.32569 3.37453 6.27687 3.38424 6.23131 3.40311C6.18576 3.42198 6.14437 3.44963 6.1095 3.4845C6.07463 3.51937 6.04698 3.56076 6.02811 3.60631C6.00924 3.65187 5.99953 3.70069 5.99953 3.75C5.99953 3.79931 6.00924 3.84813 6.02811 3.89369C6.04698 3.93924 6.07463 3.98063 6.1095 4.0155L7.71975 5.625H3.375Z"
          fill="#8B33F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_3740_1721">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
