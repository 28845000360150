import { NavbarUserDropdownMenu } from './NavbarUserDropdownMenu';
import React from 'react';
import classNames from '../../helper/classNames';
import { Button } from '../../components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../auth';
import {
  HomePageLogo,
  signout,
  Navbar,
  pageTitles,
  NavbarPlanInformation,
} from './NavbarComponents';
import { useWorkspacePlan } from '../../hooks/useWorkspacePlan';
import { Link } from 'react-router-dom';

export const HomeNavbar = ({
  isAuthenticated,
  workspaceId,
  isTrial,
  plan,
  workspacePlanValidUntil,
  currentSubscriptionRenewalTime,
  showCreateRSession = false,
  setIsNewRSessionModalOpen = null,
}) => {
  const auth = useSelector((_st) => _st.auth);
  const dispatch = useDispatch();

  const { isAppSumo, isLoading, workspacePlanLabel } = useWorkspacePlan();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Navbar>
      <ul className="flex w-full justify-between items-center">
        <ul className="flex items-center">
          <li>
            <HomePageLogo workspaceId={workspaceId} />
          </li>
        </ul>

        <div className="flex gap-5">
          {isLoading ? null : isAppSumo ? (
            <Link
              to={`/workspace/billing?workspaceId=${workspaceId}`}
              className="text-blue-dark font-medium flex justify-center items-center text-xs cursor-pointer underline decoration-blue-dark"
            >
              {workspacePlanLabel} - Lifetime access
            </Link>
          ) : (
            <NavbarPlanInformation
              workspaceId={workspaceId}
              isTrial={isTrial}
              plan={plan}
              trialUntil={workspacePlanValidUntil}
              workspacePlanValidUntil={workspacePlanValidUntil}
              currentSubscriptionRenewalTime={currentSubscriptionRenewalTime}
            />
          )}
          <li className="hidden md:block">
            <NavbarUserDropdownMenu
              pageTitle={pageTitles.home}
              showCreateRSession={showCreateRSession}
              setIsNewRSessionModalOpen={setIsNewRSessionModalOpen}
            />
          </li>
        </div>

        <li className="md:hidden shrink-0">
          <Button
            color={Button.colors.PURPLE_LITE}
            padding={Button.padding.SMALL}
            onClick={() => signout(auth, dispatch, logout)}
          >
            <span className="text-sm">Sign Out</span>
          </Button>
        </li>
      </ul>
    </Navbar>
  );
};

export const WorkspaceOptionRightMenu = ({
  children,
  isActive,
  handleSelectWorkspace,
}) => {
  return (
    <li onClick={handleSelectWorkspace} className="bg-white max-w-[240px]">
      <div
        className={classNames(
          'duration-100 text-sm relative py-2 bg-purple/5 border-b border-solid border-b-gray text-[#696F8C] font-medium flex items-center justify-start px-5 hover:bg-purple/15 active:bg-opacity-10 hover:text-purple cursor-pointer pl-9',
          isActive && 'bg-purple/10 !text-purple'
        )}
      >
        {isActive && (
          <div className="absolute p-[1px] left-0 bg-purple h-full rounded-r-md"></div>
        )}

        {children}
      </div>
    </li>
  );
};
