import React, { useState } from 'react';
import classNames from '../../helper/classNames';
import { AngleIcon } from '../../components/icons/AngleIcon';
import { isMobile } from '../../helper';
import { Link } from 'react-router-dom';
import { getMeetingTitle } from '../../components/richText/helpers';
import moment from 'moment/moment';
import { episodeTypes } from 'zync-common/types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const checkHasContentKit = (episode) =>
  episode.contentKitStatus !== null &&
  episode.contentKitStatus.message !== 'No highlights available';

export const checkIsIncomplete = (episode) => {
  const hasNoContentKit = !checkHasContentKit(episode);
  const isPastEndDate =
    moment().diff(moment(episode.scheduledEvent.endDate), 'hours') >= 24;

  return (hasNoContentKit && isPastEndDate) || hasNoContentKit;
};

export const checkIsUpcoming = (episode) => {
  const hasNoDateSet =
    !episode.scheduledEvent.endDate || !episode.scheduledEvent.startDate;

  if (hasNoDateSet) {
    return true;
  }

  const hasNoContentKit = !checkHasContentKit(episode);
  const today = moment();
  const endDate = moment(episode.scheduledEvent.endDate);

  return hasNoContentKit && today.isBefore(endDate);
};

export const checkIsCompletedRecently = (episode) => {
  const hasContentKit = checkHasContentKit(episode);

  const TIME_CONSIDERED_COMPLETED_RECENTLY = 2; // 2 days with fractions

  const timeDifference = moment().diff(
    moment(episode.scheduledEvent.endDate),
    'days'
  );

  return (
    hasContentKit &&
    timeDifference >= 0 &&
    timeDifference < TIME_CONSIDERED_COMPLETED_RECENTLY
  );
};

export const checkIsSoloEpisode = (episode) => {
  return (
    episode?.settings?.episodeType === episodeTypes.solo ||
    episode?.settings?.episodeType === episodeTypes.soloBlogBased ||
    episode?.settings?.episodeType === episodeTypes.soloProfileBased ||
    episode?.settings?.episodeType === episodeTypes.soloIdeaBased ||
    episode?.settings?.episodeType === episodeTypes.placeholder
  );
};

export const checkIsFailedEpisode = (episode) => {
  return episode?.settings?.episodeType === episodeTypes.failed;
};

export const checkIsCompletedLater = (episode) => {
  const hasContentKit = checkHasContentKit(episode);
  return hasContentKit && !checkIsCompletedRecently(episode);
};

export const sortByStartDate = (episodes) =>
  episodes.slice().sort((a, b) => {
    if (!a.scheduledEvent.startDate) {
      return 1;
    }

    if (!b.scheduledEvent.startDate) {
      return -1;
    }
    return (
      new Date(a.scheduledEvent.startDate) -
      new Date(b.scheduledEvent.startDate)
    );
  });

export const Container = React.memo(({ children }) => (
  <div className="flex flex-col overflow-hidden p-4 md:p-12 gap-6 max-w-[1080px] mx-auto">
    {children}
  </div>
));

export const SectionHeader = React.memo(({ text }) => (
  <h2 className="text-xl font-semibold mb-5">{text}</h2>
));

export const SectionAccordion = ({ children, text }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <div className="relative">
      <button
        onClick={() => setIsCollapsed((wasCollapsed) => !wasCollapsed)}
        className={classNames(
          'w-3 absolute right-4 top-2 flex justify-center items-center',
          isCollapsed ? 'rotate-90' : 'rotate-[270deg]'
        )}
      >
        <AngleIcon width="18px" className="fill-blue-gray" />
      </button>
      <SectionHeader text={text} />
      {isCollapsed && children}
    </div>
  );
};

export const EventLink = ({
  children,
  event: {
    meetingSeriesId,
    title,
    autoLaunchConfig: { templateKey },
    workspace,
  },
  setShowDesktopOnlyModal,
}) => {
  const history = useHistory();
  const handleOnClick = () => {
    if (isMobile) {
      setShowDesktopOnlyModal('eventPage');
    } else {
      history.push(
        templateKey === 'meeting_speaker_interview_1x1' ||
          templateKey === 'trial_episode_template'
          ? `/e/${meetingSeriesId}/details?workspaceId=${workspace?.workspaceId}`
          : `/e/${meetingSeriesId}/olddetails`
      );
    }
  };
  return (
    <Link
      className="underline flex gap-3.5"
      to="#"
      onClick={handleOnClick}
      style={{ wordBreak: 'break-word' }}
    >
      {children ? children : getMeetingTitle(title)}
    </Link>
  );
};
