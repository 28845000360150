import { checkAppSumoFeature, PLANS } from 'zync-common/zyncCustomerPlans';
import { useWorkspacePlan } from '../../../hooks/useWorkspacePlan';
import { Switch } from '../../../components/common/Switch';
import { useCallback, useState } from 'react';
import { updateEpisodeSettingsApi } from '../../../helper/api';
import classNames from '../../../helper/classNames';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CloudUploadIcon } from '../../../components/icons/CloudUploadIcon';

export const PublishReels = ({ series, highlights, localDispatch }) => {
  const { meetingSeriesId } = series || {};
  const [settings, setSettings] = useState(series?.settings);
  const { highlights: highlightsSettings = {} } = settings || {};
  const highlight = (highlights || [])?.[0];
  const { highlightId } = highlight || {};
  const highlightAddedToVideoReel = !!!highlightsSettings[highlightId]?.hidden;

  const updateHighlightsSettings = useCallback(
    async (checked) => {
      const newHighlights = {
        ...highlightsSettings,
        [highlightId]: {
          ...highlightsSettings[highlightId],
          hidden: !checked,
        },
      };
      const newSettings = {
        ...settings,
        highlights: newHighlights,
      };
      setSettings(newSettings);
      const result = await updateEpisodeSettingsApi(meetingSeriesId, {
        highlights: newHighlights,
      });
      localDispatch &&
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      setSettings(result.settings);
    },
    [highlightId, highlightsSettings, localDispatch, meetingSeriesId, settings]
  );

  if (!highlight) return null;

  return (
    <div className="flex flex-col">
      <div className="w-full bg-[#EAEAEA] h-[1px]"></div>
      <div className="px-5 w-full flex justify-between items-center py-4">
        <span className="text-[#617182] font-[Poppins] font-medium">
          Add to Video Reel
        </span>
        <Switch
          useBooleanText={false}
          checked={highlightAddedToVideoReel}
          onChangeValue={async (checked) => {
            updateHighlightsSettings(checked);
          }}
        />
      </div>
      <div className="w-full bg-[#EAEAEA] h-[1px]"></div>
    </div>
  );
};

const ArrowRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <g clipPath="url(#clip0_2350_7923)">
      <circle cx="13" cy="13" r="13" fill="white" />
      <path
        d="M9 13H17"
        stroke="#9622A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 9L17 13L13 17"
        stroke="#9622A8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2350_7923">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const PublishCustomDomain = ({
  series,
  highlights,
  localDispatch,
  workspace,
  showSpacer,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { plan, isAppSumo, activeSubscription, isTrial } = useWorkspacePlan();
  const isSoloPlan = plan === PLANS.solo.id;
  const isCustomDomainFeatureAvailable =
    (!isTrial && activeSubscription && isSoloPlan) ||
    (isAppSumo &&
      activeSubscription &&
      checkAppSumoFeature('customDomain', plan));
  const customDomainUrl = workspace?.settings?.domain;
  const { meetingSeriesId } = series || {};
  const [settings, setSettings] = useState(series?.settings);
  const { highlights: highlightsSettings = {} } = settings || {};
  const highlight = (highlights || [])?.[0];
  const { highlightId } = highlight || {};
  const highlightPublishedToCustomDomain =
    !!highlightsSettings[highlightId]?.publishToCustomDomain;

  const updateHighlightsSettings = useCallback(
    async (value) => {
      setIsLoading(true);
      const newHighlights = {
        ...highlightsSettings,
        [highlightId]: {
          ...highlightsSettings[highlightId],
          publishToCustomDomain: value,
        },
      };
      const result = await updateEpisodeSettingsApi(meetingSeriesId, {
        highlights: newHighlights,
      });
      localDispatch &&
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      setSettings(result.settings);
      setIsLoading(false);
    },
    [highlightId, highlightsSettings, localDispatch, meetingSeriesId]
  );

  return (
    <div className="flex flex-col gap-5">
      {!isCustomDomainFeatureAvailable && (
        <div className="flex flex-col justify-center items-center w-full h-full gap-8">
          <span className="font-[Poppins] text-[#617182] font-medium">
            Connect to Custom Domain
          </span>
          <Link
            className="w-[247px] h-[40px] relative flex bg-purple hover:bg-gradient-to-r hover:from-[#5C41FF] hover:to-[#9820A3] rounded-[10px] transition-colors duration-500 overflow-hidden font-montserrat text-white group"
            to={`/workspace/billing?workspaceId=${workspace?.workspaceId}`}
          >
            <span className="flex w-full h-full items-center justify-center font-bold transition-transform duration-500 ease-in-out transform group-hover:-translate-y-full">
              CONNECT
            </span>
            <div className="absolute -left-full flex flex-col transform transition-transform duration-500 ease-in-out group-hover:translate-x-[155%] text-left top-1/2 -translate-y-1/2">
              <span className="text-xxs text-white">Subscribe to</span>
              <span className="text-xs text-white">ZYNC SOLO PROFESSIONAL</span>
            </div>
            <div className="absolute -right-10 transform transition-transform duration-500 ease-in-out group-hover:-translate-x-[180%] top-1/2 -translate-y-1/2 -rotate-90 group-hover:rotate-0">
              <ArrowRightIcon />
            </div>
          </Link>
        </div>
      )}
      {!customDomainUrl && isCustomDomainFeatureAvailable && (
        <div className="flex flex-col justify-center items-center w-full h-full gap-8">
          <span className="font-[Poppins] text-[#617182] font-medium">
            Connect to Custom Domain
          </span>
          <Link
            className="w-[247px] h-[40px] relative flex bg-purple rounded-[10px] overflow-hidden font-montserrat text-white"
            to={`/workspace/integrations?workspaceId=${workspace?.workspaceId}`}
          >
            <span className="flex w-full h-full items-center justify-center font-bold">
              CONNECT
            </span>
          </Link>
        </div>
      )}
      {customDomainUrl && isCustomDomainFeatureAvailable && (
        <div className="flex flex-col px-5">
          <div className="flex items-center gap-2.5">
            <span className="text-[#617182] font-medium font-[Poppins]">
              Publish to
            </span>
            {highlightPublishedToCustomDomain && (
              <div className="bg-[#E2FCF3] px-2.5 py-0.5 rounded-2xl flex items-center justify-center">
                <span className="font-montserrat text-[#1CB680] text-xxs font-bold">
                  PUBLISHED
                </span>
              </div>
            )}
          </div>
          <span className="text-purple text-xs font-medium">
            {customDomainUrl}
          </span>
          <div className="flex justify-center items-center">
            <button
              className={classNames(
                'w-[147px] h-[40px] mt-8 rounded-lg justify-center items-center flex',
                isLoading
                  ? 'bg-white'
                  : highlightPublishedToCustomDomain
                  ? 'bg-[#FF6464]'
                  : 'bg-purple'
              )}
              onClick={() => {
                updateHighlightsSettings(!highlightPublishedToCustomDomain);
                toast(
                  'The changes will take a few minutes to reflect on your custom domain.'
                );
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <LoadingSpinner width="20px" />
              ) : (
                <span className="font-montserrat text-[15px] font-bold text-white">
                  {highlightPublishedToCustomDomain ? 'UNPUBLISH' : 'PUBLISH'}
                </span>
              )}
            </button>
          </div>
        </div>
      )}
      {showSpacer && <div className="w-full bg-[#EAEAEA] h-[1px]"></div>}
    </div>
  );
};

export const PublishCustomDomainV2 = ({
  workspace,
  isPublishButtonDisabled,
  isLoading,
  isCustomDomainFeatureAvailable,
  customDomainUrl,
  updateHighlightsSettings,
  error,
  highlightPublishedToCustomDomain,
}) => {
  return (
    <div className="flex flex-col gap-5">
      {!isCustomDomainFeatureAvailable && (
        <div className="flex flex-col-reverse justify-center items-center w-full h-full gap-2">
          <span className="font-[Poppins] text-[#617182] font-medium">
            Connect to Custom Domain
          </span>
          <Link
            className="w-[247px] h-[40px] relative flex bg-purple hover:bg-gradient-to-r hover:from-[#5C41FF] hover:to-[#9820A3] rounded-[10px] transition-colors duration-500 overflow-hidden font-montserrat text-white group"
            to={`/workspace/billing?workspaceId=${workspace?.workspaceId}`}
          >
            <span className="flex w-full h-full items-center justify-center font-bold transition-transform duration-500 ease-in-out transform group-hover:-translate-y-full">
              CONNECT
            </span>
            <div className="absolute -left-full flex flex-col transform transition-transform duration-500 ease-in-out group-hover:translate-x-[155%] text-left top-1/2 -translate-y-1/2">
              <span className="text-xxs text-white">Subscribe to</span>
              <span className="text-xs text-white">ZYNC SOLO PROFESSIONAL</span>
            </div>
            <div className="absolute -right-10 transform transition-transform duration-500 ease-in-out group-hover:-translate-x-[180%] top-1/2 -translate-y-1/2 -rotate-90 group-hover:rotate-0">
              <ArrowRightIcon />
            </div>
          </Link>
        </div>
      )}
      {!customDomainUrl && isCustomDomainFeatureAvailable && (
        <div className="flex flex-col-reverse justify-center items-center w-full h-full gap-2">
          <span className="font-[Poppins] text-[#617182] font-medium">
            Connect to Custom Domain
          </span>
          <Link
            className="w-[247px] h-[40px] relative flex bg-purple rounded-[10px] overflow-hidden font-montserrat text-white"
            to={`/workspace/integrations?workspaceId=${workspace?.workspaceId}`}
          >
            <span className="flex w-full h-full items-center justify-center font-bold">
              CONNECT
            </span>
          </Link>
        </div>
      )}
      {customDomainUrl && isCustomDomainFeatureAvailable && (
        <div className="flex flex-col">
          {!highlightPublishedToCustomDomain ? (
            <div className="flex flex-col gap-1.5 items-end">
              <button
                className={classNames(
                  'text-white text-[13px] flex gap-2 items-center px-3 py-2.5',
                  isPublishButtonDisabled ? 'bg-[#C8AAF4]' : 'bg-purple'
                )}
                disabled={isPublishButtonDisabled}
                onClick={() => {
                  updateHighlightsSettings(true);
                  toast(
                    'The changes will take a few minutes to reflect on your custom domain.'
                  );
                }}
              >
                {isLoading ? (
                  <LoadingSpinner width="20px" />
                ) : (
                  <>
                    <CloudUploadIcon /> Publish
                  </>
                )}
              </button>
              <span className="text-[#585858] text-xxs font-semibold">
                Publish to Custom Domain
              </span>
            </div>
          ) : (
            <>
              {isLoading ? (
                <LoadingSpinner width="20px" />
              ) : (
                <div className="flex gap-6 items-center">
                  <button
                    className={classNames(
                      'text-white text-[13px] flex gap-2 items-center px-3 py-2.5',
                      isPublishButtonDisabled ? 'bg-[#C8AAF4]' : 'bg-purple'
                    )}
                    disabled={isPublishButtonDisabled}
                    onClick={() => {
                      updateHighlightsSettings(false);
                      toast(
                        'The changes will take a few minutes to reflect on your custom domain.'
                      );
                    }}
                  >
                    Unpublish
                  </button>
                  <button
                    className={classNames(
                      'text-white text-[13px] flex gap-2 items-center px-3 py-2.5',
                      isPublishButtonDisabled ? 'bg-[#C8AAF4]' : 'bg-purple'
                    )}
                    disabled={isPublishButtonDisabled}
                    onClick={() => {
                      updateHighlightsSettings(true);
                      toast(
                        'The changes will take a few minutes to reflect on your custom domain.'
                      );
                    }}
                  >
                    <CloudUploadIcon /> Republish
                  </button>
                </div>
              )}
            </>
          )}
          {error && (
            <span className="mt-1.5 text-[#EA0808] text-xxs font-semibold">
              {error}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
