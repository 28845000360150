import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getSpeakerInviteUrl } from '../EventLanding/Plan/PlanInviteSpeakerCard';
import { CompletedContentKitIcon } from './MobileSoloPortalIcons';
import { getStaticAssetUrl } from '../../helper/getStaticAssetUrl';
import { useState } from 'react';
import classNames from '../../helper/classNames';
import { CaretIconV2 } from '../../components/icons/CaretIconV2';
import { RightAngleIcon } from '../../components/icons/RightAngleIcon';

const mobileAlert = getStaticAssetUrl('mobile-alert.png');

export const MobileSoloEpisodeCard = ({ series, featureFlags }) => {
  const {
    meetingSeriesId,
    settings,
    ORSessions = [],
    workspace,
    title,
  } = series || {};

  const {
    isZyncUp = false,
    seekerDetails = null,
    responderDetails = null,
  } = settings || {};

  const { useContentKitWebhooks = false } = featureFlags || {};

  const isSR = !!seekerDetails && !!responderDetails;
  const isR = !!seekerDetails && !!!responderDetails;

  const contentKitStatus = useContentKitWebhooks
    ? series?.contentKitStatuses?.CONTENT_KIT_PAGE?.status?.type
    : series?.contentKitStatus?.status;
  const completed = Boolean(contentKitStatus); // any status

  const [showORSessions, setShowORSessions] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          'bg-[#F0F2F5] border border-[#EDEFF2] px-[15px] py-4 flex gap-6 items-center relative',
          showORSessions ? 'rounded-t-[15px]' : 'rounded-[15px]'
        )}
        onClick={() => {
          switch (true) {
            case isZyncUp: {
              if (completed) {
                setShowORSessions((prevState) => !prevState);
              } else {
                window.location.href = getSpeakerInviteUrl(
                  series.settings.eventPresenters[0],
                  series.meetingSeriesId,
                  true
                );
              }
              break;
            }
            default: {
              window.location.href = completed
                ? `/e/${meetingSeriesId}/details/publish?workspaceId=${workspace?.workspaceId}`
                : getSpeakerInviteUrl(
                    series.settings.eventPresenters[0],
                    series.meetingSeriesId,
                    true
                  );
            }
          }
        }}
      >
        {(isZyncUp || isR) && (
          <div className="absolute left-0 top-1/2 -translate-y-1/2 h-[48px] w-1.5 bg-[#FF2D2D] rounded-r-[50px]"></div>
        )}
        <div className="flex-[5_0_0%] flex gap-1.5 items-center">
          {isZyncUp && contentKitStatus === 'COMPLETE' && (
            <button
              className={classNames(
                'transition-transform',
                showORSessions ? 'rotate-90' : ''
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShowORSessions((prevState) => !prevState);
              }}
              tabIndex={-1}
            >
              <CaretIconV2 width="12" height="12" className="fill-[#333333]" />
            </button>
          )}
          <div className="text-[#444444] text-sm font-medium break-words">
            {title}
          </div>
        </div>

        {/*<Link
        to={
          completed
            ? `/e/${meetingSeriesId}/details/publish?`
            : `/e/${meetingSeriesId}/details?workspaceId=${workspace?.workspaceId}`
        }
        className="max-w-[60%]"
      >
        <div className="text-[#444444] text-sm font-medium break-words">
          {title}
        </div>
      </Link>*/}
        <div className="flex-[1_0_0%] flex justify-center items-center">
          {!completed && (
            <button
              className={classNames(
                'w-[87px] h-[32px] flex justify-center items-center text-white font-montserrat font-bold text-xs rounded-lg',
                isZyncUp || !!seekerDetails ? 'bg-[#F9155B]' : 'bg-[#5C41FF]'
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                window
                  .open(
                    getSpeakerInviteUrl(
                      series.settings.eventPresenters[0],
                      series.meetingSeriesId,
                      true
                    ),
                    '_blank'
                  )
                  .focus();
              }}
            >
              Launch
            </button>
          )}
          {contentKitStatus === 'COMPLETE' ? (
            <>
              {isZyncUp ? (
                <Link
                  to={`/e/${meetingSeriesId}/details/zyncup?`}
                  className="w-[87px] h-[32px] flex justify-center items-center text-white font-montserrat font-bold text-xs bg-[#F9155B] rounded-lg"
                >
                  Zync Up
                </Link>
              ) : (
                <Link
                  to={`/e/${meetingSeriesId}/details/publish?`}
                  className="w-[87px] h-[32px] flex justify-center items-center"
                >
                  <CompletedContentKitIcon
                    color={isSR || isR ? '#F9155B' : '#5C41FF'}
                  />
                </Link>
              )}
            </>
          ) : null}
          {(contentKitStatus === 'PROCESSING' ||
            contentKitStatus === 'WAITING_FOR_CALLBACK' ||
            contentKitStatus === 'WAITING FOR INPUT' ||
            contentKitStatus === 'INCOMPLETE') && (
            <div className="rounded-md w-[87px] h-[30px] flex justify-center items-center bg-[#00C92C] gap-1 px-1.5">
              <img src={mobileAlert} className="w-[17px] h-[17px]" alt="" />
              <span className="font-roboto text-white font-bold text-[7px]">
                Content Kit is being processed
              </span>
            </div>
          )}
        </div>
      </div>
      {isZyncUp && showORSessions && (
        <>
          {ORSessions.length ? (
            ORSessions.map((ms, index) => {
              return (
                <div
                  className={classNames(
                    'bg-[#F0F2F5] border border-[#EDEFF2] pl-[25px] pr-[15px] py-4 flex gap-6 items-center relative',
                    index === ORSessions?.length - 1 && 'rounded-b-[15px]'
                  )}
                  onClick={() => {
                    window.location.href = `/e/${ms.meetingSeriesId}/details/publish?workspaceId=${workspace?.workspaceId}`;
                  }}
                  key={ms.meetingSeriesId}
                >
                  <div className="flex-[5_0_0%] flex gap-1.5 items-center">
                    <RightAngleIcon />
                    <div className="text-[#444444] text-sm font-medium break-words">
                      {title}
                    </div>
                  </div>
                  <div className="flex-[1_0_0%] flex gap-1.5 items-center">
                    <Link
                      to={`/e/${meetingSeriesId}/details/publish?`}
                      className="w-[87px] h-[32px] flex justify-center items-center"
                    >
                      <CompletedContentKitIcon color={'#F9155B'} />
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bg-[#F0F2F5] border border-[#EDEFF2] rounded-b-[15px] pl-[25px] pr-[15px] py-4 flex items-center relative">
              <RightAngleIcon />
              <div className="w-8 h-8 bg-[#D9D9D9] rounded-[10px] ml-2"></div>
              <span className="font-roboto text-[#777777] text-sm font-bold ml-2">
                No responses so far
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
