const meetingTypes = {
  STANDARD: 'STANDARD',
  QUICK_START: 'QUICK_START',
};

const mediaTypes = {
  landscape: 'landscape',
  square: 'square',
  portrait: 'portrait',
};

const highlightTypes = {
  SCENE: 'scene',
  QA: 'qa',
  RAISE_HAND: 'raiseHand',
  WORD_CLOUD: 'wordCloud',
  WELCOME_GAME: 'welcomeGame',
  COMMS_GAME: 'commsGame',
  REACTION_MONTAGE: 'reactionsMontage',
  INTERVIEW: 'interview',
};

const sceneTemplateKeyMap = {
  interview: 'scene_discussion_point',
  speaker_intro: 'scene_speaker_intro',
  speaker_intro_2: 'scene_speaker_intro_2',
  panel_interview: 'scene_interview_panel_question',
  'q&a': 'scene_q&a',
  'q&a_no_speaker': 'scene_q&a_no_speaker',
  thumbnail_discussion_point: 'thumbnail_discussion_point',
  thumbnail_qa: 'thumbnail_qa',
  scene_welcome_1x1: 'scene_welcome_1x1',
  scene_welcome_no_speaker: 'scene_welcome_no_speaker',
  scene_discussion_point: 'scene_discussion_point',
  scene_discussion_point_no_speaker: 'scene_discussion_point_no_speaker',
  'scene_q&a': 'scene_q&a',
  'scene_q&a_no_speaker': 'scene_q&a_no_speaker',
  scene_open_discussion: 'scene_open_discussion',
  scene_thankyou: 'scene_thankyou',
  scene_thankyou_no_speaker: 'scene_thankyou_no_speaker',
  scene_discussion_point_2: 'scene_discussion_point_2',
  scene_discussion_point_3: 'scene_discussion_point_3',
};

const highlightTypeToThumbnailSceneTemplate = {
  [highlightTypes.INTERVIEW]: sceneTemplateKeyMap.thumbnail_discussion_point,
  [highlightTypes.QA]: sceneTemplateKeyMap.thumbnail_qa,
};

const limitEnforcements = {
  MAX_ATTENDEES: 500,
  MAX_RECORDING_TIME_IN_SECONDS: 10800,
};

const episodeTypes = {
  default: 'default',
  solo: 'solo',
  soloBlogBased: 'soloBlogBased',
  soloScriptBased: 'soloScriptBased',
  soloProfileBased: 'soloProfileBased',
  soloIdeaBased: 'soloIdeaBased',
  placeholder: 'placeholder',
  zyncup: 'zyncup',
  responder: 'responder',
  failed: 'failed',
};

const showPosterSettings = {
  default: 'Default show poster',
  custom: 'Custom show poster',
};

const DEFAULT_LOGO_URL =
  'https://cdn.zync.ai/assets/static/default_blank_logo.png';

module.exports = {
  meetingTypes,
  mediaTypes,
  highlightTypes,
  sceneTemplateKeyMap,
  limitEnforcements,
  highlightTypeToThumbnailSceneTemplate,
  episodeTypes,
  showPosterSettings,
  DEFAULT_LOGO_URL,
};
