import React from 'react';
import { Modal, ModalWindow } from './Modal';

export const FailedSessionCreationModal = ({ onClose }) => {
  return (
    <ModalWindow size={Modal.size.sm} onCancel={onClose} showCancel={true}>
      <div className="flex flex-col gap-2.5 p-8">
        <div className="text-xl text-blue-dark font-semibold">
          Unable to Create Session
        </div>
        <div className="text-sm text-blue-gray">
          We are facing an issue with AI Suggest. Please try again in some time.
        </div>
      </div>
    </ModalWindow>
  );
};
