export const CaretIconV2 = ({
  width = '15',
  height = '18',
  className = 'fill-purple',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 18"
    className={className}
  >
    <path d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z" />
  </svg>
);
